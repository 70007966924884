import React from 'react';
import { EventInput } from '@fullcalendar/core';

interface Props {
  selectedEvent: EventInput;
  closeModal: () => void;
}

const EditFamilyLesson: React.FC<Props> = ({ selectedEvent, closeModal }) => {
  if (!selectedEvent || !selectedEvent.extendedProps) {
    return (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center">
        <div className="bg-white p-4 mx-auto w-96 h-48 rounded-lg shadow-lg flex flex-col">
          <div className="flex justify-between">
            <h1 className="text-fpcred font-bold text-xl">Errore</h1>
            <button onClick={closeModal}>
              <p className="text-fpcred font-bold text-xl">X</p>
            </button>
          </div>
          <div className="flex flex-1 flex-col justify-center items-center">
            <p className="text-red-500">Errore nel caricare i dettagli della lezione.</p>
          </div>
        </div>
      </div>
    );
  }
  const { teacher, subject } = selectedEvent.extendedProps;
  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center">
      <div className="bg-white p-4 mx-auto w-96 h-48 rounded-lg shadow-lg flex flex-col">
        <div className="flex justify-between">
          <h1 className="text-fpcred font-bold text-xl">Modifica la lezione</h1>
          <button onClick={closeModal}>
            <p className="text-fpcred font-bold text-xl">X</p>
          </button>
        </div>
        <div className="flex flex-1 flex-col">
          <p>Figlio: {selectedEvent.title}</p>
          <p>Insegnante: {teacher.first_name} {teacher.last_name}</p>
          <p>Materia: {subject.name}</p>
        </div>
        <div className="flex justify-center gap-4">
          <button className="bg-fpcred text-white font-bold rounded-lg w-1/3 p-2">Modifica</button>
          <button className="bg-fpcred text-white font-bold rounded-lg w-1/3 p-2">Annulla</button>
        </div>
      </div>
    </div>
  );
};

export default EditFamilyLesson;
