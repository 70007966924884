import axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_URL; //  || 'https://dev.api.formandopercorsi.com'

// print the base url
console.log('baseURL:', baseURL);

// Crea un'istanza di Axios
const api = axios.create({
  baseURL: baseURL + '/api',
  // baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  }
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  config.headers['Authorization'] = token ? `Bearer ${token}` : '';
  return config;
});

export default api;