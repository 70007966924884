import React from 'react';
import { Card } from '../Card';

interface SchoolTypeSelectionProps {
  options: string[];
  onSchoolTypeSelection: () => void;
  setSelectedSchoolType: (selectedSchoolType: string) => void;
}

const SchoolTypeSelection: React.FC<SchoolTypeSelectionProps> = ({
  options,
  onSchoolTypeSelection,
  setSelectedSchoolType,
}) => {
  function onclick(selectedSchoolLevel: string) {
    setSelectedSchoolType(selectedSchoolLevel);
    onSchoolTypeSelection();
  }
  return (
    <div className='flex flex-col items-center justify-center gap-y-4'>
        <h2 className='text-2xl font-bold mb-4'>Quale tipo frequenta?</h2>
      <div className="flex flex-wrap gap-4 sm:gap-6 md:gap-8 lg:gap-10 justify-center md:max-w-3xl">
        {options.map((option, index) => (
          <Card
            key={index} // Oppure `key={option.type}` se `type` è garantito univoco
            title={option}
            onClick={() => onclick(option)}
          />
        ))}
      </div>
    </div>
  );
};

export default SchoolTypeSelection;
