import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { InputField } from '../components/InputField';
export type LoginType = 'family' | 'teacher' | 'admin';
function Login() {
  const router = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [category, setCategory] = useState<LoginType>('family');
  const { login, auth, logout } = useAuth();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login(email, password, category);
  };

  function goToForgotPassword() {
    router('/forgot-password');
  }
  if (auth.isAuth) {
    if (auth.isAdmin) {
      router('/admin/home');
    } else if (auth.isTeacher) {
      router('/teacher/home');
    } else if (auth.isFamily) {
      router('/family/home');
    } else {
      logout();
    }
  }

  return (
    <div className="flex items-center justify-center h-screen bg-fpcwhite">
      <div className="">
        <div className="flex">
          <button
            onClick={() => setCategory('family')}
            className={` ${
              category === 'family' ? 'bg-white border-t-8 border-fpcred rounded-tl-3xl' : 'bg-transparent'
            } text-center text-2xl font-bold w-1/2`}>
            Famiglia
          </button>
          <button
            onClick={() => setCategory('teacher')}
            className={` ${
              category === 'teacher' ? 'bg-white border-t-8 border-fpcred rounded-tr-3xl' : 'bg-transparent'
            } text-center text-2xl font-bold w-1/2`}>
            Insegnante
          </button>
        </div>
        <div className="py-5 px-8 bg-white rounded-b-3xl shadow-xl">
          <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
            <h2 className="text-center text-2xl font-bold">{t('login.title').toUpperCase()}</h2>
            <p className="text-center text-xl">{t('login.description')}</p>
            <InputField
              id="email"
              type="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder={t('login.emailPlaceholder')}
              required
              autocomplete="email"
            />
            <InputField
              id="password"
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder={t('login.passwordPlaceholder')}
              required
              autocomplete="current-password"
            />
            <button type="submit" className="bg-fpcred text-white px-4 py-2 rounded-full">
              {t('login.button')}
            </button>
            <div className="text-center">
              <p className="text-xl font-normal">
                {t(`login.${category === 'family' ? 'familyNotRegistered' : 'teacherNotRegistered'}`)}
              </p>
              <a
                // href={`/${category === 'family' ? 'register' : 'workwithus'}`}
                href={`${category === 'family' ? '/register' : 'mailto:info.insegnante@formandopercorsi.com'}`}
                className="text-fpcred text-2xl font-bold">
                {t(`login.${category === 'family' ? 'familyRegister' : 'teacherRegister'}`)}
              </a>
              <p className="text-xl font-normal text-fpcred cursor-pointer" onClick={goToForgotPassword}>
                Hai dimenticato la password?
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
