import { useNavigate,useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import React from 'react';

const withAuth = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const WithAuthComponent = (props: any) => {
    const router = useNavigate();
    const { auth } = useAuth();
    const location = useLocation();

    useEffect(() => {
      if(Object.keys(auth).length === 0) {
        return;
      }
      if (!auth.isAuth && auth.isAuth !== undefined) {
        console.log('No auth, redirecting...');
        router('/login');
      }
      if (location.pathname.includes("teacher") && !auth.isTeacher && auth.isFamily) {
        router('/family/home');
      }
      if (location.pathname.includes("family") && auth.isTeacher && !auth.isFamily) {
        router('/teacher/home');
      }
      if(location.pathname.includes("admin/") && !auth.isAdmin) {
        router('/notfound');
      }
    }, [auth, router]);

    return auth.isAuth ? <WrappedComponent {...props} /> : null;
  };

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WithAuthComponent.displayName = `withAuth(${wrappedComponentName})`;

  return WithAuthComponent;
};

export default withAuth;
