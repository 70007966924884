import React, { useEffect } from 'react';
import api from '../utils/api';
import { ReactComponent as Avatar } from '../ui/Avatar.svg';
import Calendar from './Calendar';
import { BookLessonData } from '../model/BookLessonData';
import { DateSelectArg } from '@fullcalendar/core';
import { ReactComponent as TeacherImg } from '../ui/TeacherImg.svg';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';

interface BookLessonProps {
  // Define the props for your component here
}

const BookLesson: React.FC<BookLessonProps> = (props) => {
  type steps =
    | 'selectChild'
    | 'selectSubject'
    | 'selectDuration'
    | 'selectLocation'
    | 'selectTeacher'
    | 'selectDate'
    | 'selectAvailableTeacher'
    | 'payment';

  const [step, setStep] = React.useState<steps>('selectChild');
  const [familyData, setFamilyData] = React.useState<any>();
  const [bookLessonData, setBookLessonData] = React.useState<BookLessonData>({} as BookLessonData);
  const [subjects, setSubjects] = React.useState<any[]>([]);
  const [durations, setDurations] = React.useState<number[]>();
  const [locations, setLocations] = React.useState<any[]>([]);
  const [teachers, setTeachers] = React.useState<any[]>(undefined);
  const [availableTeachers, setAvailableTeachers] = React.useState<any>({
    exactAvailabilities: [],
    nearAvailabilities: [],
  });
  const [teachersInfo, setTeachersInfo] = React.useState<any[]>(
    JSON.parse(localStorage.getItem('teachersInfo') || '[]')
  );
  const { setLoading } = useLoading();
  const { setError } = useError();
  const router = useNavigate();

  useEffect(() => {
    console.log('sono nello useEffect: ', step);
    switch (step) {
      case 'selectChild':
        getFamilyInfo();
        break;
      case 'selectSubject':
        getSubjects();
        break;
      case 'selectDuration':
        getAvailableDuration();
        console.log('selectDuration');
        break;
      case 'selectLocation':
        getAvailableLocations();
        break;
      case 'selectTeacher':
        getFavoritesTeachers();
        break;
      case 'selectDate':
        console.log('selectDate');
        break;
      case 'selectAvailableTeacher':
        getAvailableTeachers();
        break;
      case 'payment':
        break;
    }
  }, [step]);

  useEffect(() => {
    console.log('familyDataSons: ', familyData);
    if (familyData && familyData.sons && familyData.sons.length === 1) {
      selectChild(0);
    }
  }, [familyData]);

  function getFamilyInfo() {
    if (!sessionStorage.getItem('familyData')) {
      setLoading(true);
      api
        .get('/family')
        .then((response) => {
          const familyData = response.data;
          console.log(familyData);
          setFamilyData({ ...familyData, status: undefined });
          sessionStorage.setItem('familyData', JSON.stringify({ ...familyData, status: undefined }));
        })
        .catch((error) => {
          console.error(error);
          setError(true, error.response.data.message, error.response.status);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const familyData = JSON.parse(sessionStorage.getItem('familyData')!);
      console.log('familyData: ', familyData);
      setFamilyData(familyData);
    }
  }
  async function getSubjects() {
    let subjectsData;
    if (!sessionStorage.getItem('subjects')) {
      setLoading(true);
      api
        .get(`family/student/${bookLessonData.child.id}/subject`)
        .then((response) => {
          subjectsData = response.data.subjects;
          setSubjects(subjectsData);
          sessionStorage.setItem('subjects', JSON.stringify(subjectsData));
        })
        .catch((error) => {
          console.error(error);
          setError(true, error.response.data.message, error.response.status);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      subjectsData = JSON.parse(sessionStorage.getItem('subjects')!);
      setSubjects(subjectsData);
    }
  }
  async function getAvailableDuration() {
    setLoading(true);
    api
      .get(`lesson/available-lengths?subject_id=${bookLessonData.subject.id}&student_id=${bookLessonData.child.id}`)
      .then((response) => {
        setDurations(response.data.lengths);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function getAvailableLocations() {
    setLoading(true);
    api
      .get(
        `lesson/available-locations?subject_id=${bookLessonData.subject.id}&student_id=${bookLessonData.child.id}&lesson_length=${bookLessonData.duration}`
      )
      .then((response) => {
        setLocations(response.data.locations);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function getFavoritesTeachers() {
    setLoading(true);
    api
      .get('family/teacher')
      .then((response) => {
        const teachers = response.data.teachers;
        if (teachers.length === 0) {
          setStep('selectDate');
          setTeachers(undefined);
          return;
        }
        setTeachers(teachers);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function getAvailableTeachers() {
    setLoading(true);
    try {
      const availabilityRequest = {
        student_id: bookLessonData.child.id,
        teacher_id: bookLessonData.teacher ? bookLessonData.teacher.id : undefined,
        subject_id: bookLessonData.subject.id,
        location: bookLessonData.location,
        required_lessons: [
          { start_date_time: bookLessonData.date.startStr, end_date_time: bookLessonData.date.endStr },
        ],
      };
      const response = await api.post('availability/search', availabilityRequest);
      const availabilities = response.data.availabilities[0];
      console.log('availabilities: ', availabilities);

      // Carica i teacherInfo già memorizzati in localStorage
      const storedTeachersInfo = localStorage.getItem('teachersInfo');
      let teachersInfo = storedTeachersInfo ? JSON.parse(storedTeachersInfo) : [];

      // Combina le disponibilità esatte e vicine
      const allAvailabilities = [...availabilities.exact_availabilities, ...availabilities.near_availabilities];

      // Trova gli ID degli insegnanti unici
      const uniqueTeacherIdsSet = new Set(allAvailabilities.map((availability: any) => availability.teacher_id));
      const uniqueTeacherIds = Array.from(uniqueTeacherIdsSet);

      // Filtra gli ID degli insegnanti che non sono già memorizzati
      const missingTeacherIds = uniqueTeacherIds.filter(
        (teacherId) => !teachersInfo.some((teacher: any) => teacher.id === teacherId)
      );

      // Ottieni le informazioni degli insegnanti mancanti
      const newTeacherInfosPromises = missingTeacherIds.map(async (teacherId) => {
        const response = await api.get(`family/teacher/${teacherId}`);
        return response.data.teacher;
      });

      const newTeacherInfos = await Promise.all(newTeacherInfosPromises);

      // Aggiorna la lista degli insegnanti e memorizza in localStorage se necessario
      if (newTeacherInfos.length > 0) {
        teachersInfo = [...teachersInfo, ...newTeacherInfos];
        setTeachersInfo(teachersInfo);
        localStorage.setItem('teachersInfo', JSON.stringify(teachersInfo));
      }

      // Imposta gli insegnanti disponibili
      setAvailableTeachers({
        exactAvailabilities: availabilities.exact_availabilities,
        nearAvailabilities: availabilities.near_availabilities,
      });
    } catch (error) {
      console.error(error);
      setError(true, error.response.data.message, error.response.status);
    } finally {
      setLoading(false);
    }
  }

  function selectChild(index: number) {
    setBookLessonData({ ...bookLessonData, child: familyData?.sons[index] });
    setStep('selectSubject');
  }

  function selectSubject(subject: any) {
    setBookLessonData({ ...bookLessonData, subject });
    setStep('selectDuration');
  }
  function selectDuration(duration: number) {
    setBookLessonData({ ...bookLessonData, duration });
    setStep('selectLocation');
  }
  function selectLocation(location: string) {
    setBookLessonData({ ...bookLessonData, location });
    setStep('selectTeacher');
  }
  function selectTeacher(teacher?: any) {
    setBookLessonData({ ...bookLessonData, teacher });
    setStep('selectDate');
  }

  function goBack() {
    switch (step) {
      case 'selectSubject':
        setStep('selectChild');
        break;
      case 'selectDuration':
        setStep('selectSubject');
        break;
      case 'selectLocation':
        setStep('selectDuration');
        break;
      case 'selectTeacher':
        setStep('selectLocation');
        break;
      case 'selectDate':
        teachers ? setStep('selectTeacher') : setStep('selectLocation');
        break;
      case 'selectAvailableTeacher':
        setStep('selectDate');
        break;
    }
  }

  function handleDateSelect(selectInfo: DateSelectArg) {
    const selectedDateStart = new Date(selectInfo.startStr);
    const selectedDateEnd = new Date(selectInfo.startStr);
    selectedDateEnd.setMinutes(selectedDateEnd.getMinutes() + bookLessonData.duration * 60);

    // Ensure the selected dates are in local time
    const selectedDateStartLocal = new Date(
      selectedDateStart.getTime() - selectedDateStart.getTimezoneOffset() * 60000
    );
    const selectedDateEndLocal = new Date(selectedDateEnd.getTime() - selectedDateEnd.getTimezoneOffset() * 60000);

    selectInfo.end = selectedDateEnd;
    selectInfo.endStr = selectedDateEndLocal.toISOString().slice(0, 19).replace('T', ' ');
    selectInfo.startStr = selectedDateStartLocal.toISOString().slice(0, 19).replace('T', ' ');

    console.log('selectInfo: ', selectInfo);
    setBookLessonData({ ...bookLessonData, date: selectInfo });
    setStep('selectAvailableTeacher');
  }

  function goToStripe(availability: any) {
    setLoading(true);
    api
      .post('lesson/single', {
        start_date_time: availability.start_date_time,
        location: bookLessonData.location,
        subject_id: bookLessonData.subject.id,
        teacher_id: availability.teacher_id,
        student_id: bookLessonData.child.id,
        size: bookLessonData.duration,
      })
      .then((response) => {
        console.log('response: ', response.data);
        window.location.href = response.data.payment_url;
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="flex flex-col gap-4 p-2 h-full">
      {step !== 'selectChild' && !(step === 'selectSubject' && familyData.sons.length === 1) && (
        <div className="max-w-fit">
          <p className="underline cursor-pointer" onClick={goBack}>
            Torna indietro
          </p>
        </div>
      )}
      {step === 'selectChild' && (
        <div className="flex flex-col">
          <h3 className="text-xl text-center">Per chi vuoi prenotare la lezione ?</h3>
          <div className="flex flex-col items-center justify-center gap-3 p-5">
            {familyData?.sons.map((son: any, index: number) => (
              <div
                key={index}
                className="flex p-4 rounded-lg border-y-2 border-y-fpcred gap-16 items-center w-full md:w-96 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
                onClick={() => selectChild(index)}>
                <Avatar />
                <div className="flex flex-col items-center justify-center">
                  <div className="flex flex-col">
                    <p className="text-base">Nome</p>
                    <span className="text-xl">{son.first_name}</span>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-base">Cognome</p>
                    <span className="text-xl">{son.last_name}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {step === 'selectSubject' && subjects && (
        <div className="flex flex-col">
          <h3 className="text-xl text-center">Di quale materia ha bisogno?</h3>
          <div className="flex flex-wrap justify-around gap-6 p-5">
            {subjects.map((subject: any, index: number) => (
              <div
                key={index}
                className="flex items-center justify-center p-4 rounded-lg border border-black w-24 h-24 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
                onClick={() => selectSubject(subject)}>
                <div className="flex flex-col items-center justify-center h-full w-full">
                  <div className="text-center">
                    <span className="text-xl">{subject.name}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {step === 'selectDuration' && durations && (
        <div className="flex flex-col">
          <h3 className="text-xl text-center">Quanto tempo vuoi prenotare?</h3>
          <div className="flex flex-wrap justify-around gap-6 p-5">
            {durations.map((duration, index) => (
              <div
                key={index}
                className="flex items-center justify-center p-4 rounded-full border border-black w-24 h-24 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
                onClick={() => selectDuration(duration)}>
                <div className="flex flex-col items-center justify-center h-full w-full">
                  <div className="text-center">
                    <span className="text-xl">{duration}H</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {step === 'selectLocation' && locations && (
        <div className="flex flex-col">
          <h3 className="text-xl text-center">Dove vuoi fare la lezione?</h3>
          <div className="flex flex-wrap justify-around gap-6 p-5">
            {Object.entries(locations).map((location, index) => (
              <div
                key={index}
                className={`flex items-center justify-center p-4 rounded-lg border-2 border-fpcred w-36 shadow-md  ${
                  location[1].available
                    ? 'cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg'
                    : 'opacity-25 cursor-not-allowed'
                }`}
                onClick={() => selectLocation(location[0])}>
                <div className="flex flex-col items-center justify-center h-full w-full">
                  <div className="text-center">
                    <span className="text-xl">{location[0]}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {step === 'selectTeacher' && teachers && (
        <div className="flex flex-col">
          <h3 className="text-xl text-center">Quale insegnante vuoi prenotare?</h3>
          <div className="flex flex-wrap justify-around gap-6 p-5">
            {teachers.map((teacher: any, index: number) => (
              <div
                key={index}
                className="flex items-center justify-center p-4 rounded-lg border border-black w-36 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg">
                <div className="flex flex-col items-center justify-center h-full w-full">
                  <div className="text-center">
                    <span className="text-xl">{teacher.first_name}</span>
                  </div>
                </div>
              </div>
            ))}
            <div
              className="flex items-center justify-center p-4 rounded-lg border border-black w-36 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
              onClick={() => selectTeacher()}>
              <div className="flex flex-col items-center justify-center h-full w-full">
                <div className="text-center">
                  <span className="text-xl">Nessuna preferenza</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {step === 'selectDate' && <Calendar bookLessonData={bookLessonData} onDateSelect={handleDateSelect} />}
      {step === 'selectAvailableTeacher' && availableTeachers && (
        <div className="flex flex-col gap-4 p-4 mx-auto w-full sm:w-[60%]">
          <div className="bg-fpcpink rounded-2xl flex justify-around items-center p-4">
            <p className="text-fpcred text-xl">{bookLessonData.date.start.toLocaleDateString('it-IT')}</p>
            <p className="text-fpcred text-xl">
              {bookLessonData.date.start.toLocaleDateString('it-IT', { weekday: 'long' }).charAt(0).toUpperCase() +
                bookLessonData.date.start.toLocaleDateString('it-IT', { weekday: 'long' }).slice(1)}
            </p>
            <p className="text-fpcred text-xl">
              {bookLessonData.date.start.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}/
              {bookLessonData.date.end.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}
            </p>
          </div>
          <div>
            <p className="text-xl">Disponibilità per l'orario richiesto</p>
          </div>
          {availableTeachers.exactAvailabilities.map((availability, index) => {
            const teacher = teachersInfo.find((teacher) => teacher.id === availability.teacher_id);
            return teacher ? (
              <div key={index} className="flex flex-col border-2 border-black rounded-lg p-4">
                <div className="flex justify-around items-center gap-4">
                  <div className="min-w-[100px]">
                    <TeacherImg className="w-full h-full" />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row gap-2">
                      <p className="text-xl">{teacher.first_name}</p>
                      <p className="text-xl">{teacher.last_name}</p>
                    </div>
                    <div>
                      <p className="text-xl">{teacher.bio}</p>
                    </div>
                    <div>
                      <button
                        className="bg-fpcred px-4 py-2 rounded-xl text-white"
                        onClick={() => goToStripe(availability)}>
                        Prenota
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null;
          })}
          <div>
            <p className="text-xl">Disponibilità vicino all'orario richiesto</p>
          </div>
          {availableTeachers.nearAvailabilities.map((availability, index) => {
            const teacher = teachersInfo.find((teacher) => teacher.id === availability.teacher_id);
            return teacher ? (
              <div key={index} className="flex flex-col border-2 border-black rounded-lg p-4">
                <div className="flex justify-around items-center gap-4">
                  <div className="min-w-[100px]">
                    <TeacherImg className="w-full h-full" />
                  </div>
                  <div className="flex flex-col">
                    <div className="bg-fpcpink rounded-2xl flex justify-around items-center p-4">
                      <p className="text-fpcred text-xl">
                        {new Date(availability.start_date_time).toLocaleTimeString('it-IT', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                        /
                        {new Date(availability.end_date_time).toLocaleTimeString('it-IT', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </p>
                    </div>
                    <div className="flex flex-row gap-2">
                      <p className="text-xl">{teacher.first_name}</p>
                      <p className="text-xl">{teacher.last_name}</p>
                    </div>
                    <div>
                      <p className="text-xl">{teacher.bio}</p>
                    </div>
                    <div>
                      <button
                        className="bg-fpcred px-4 py-2 rounded-xl text-white"
                        onClick={() => goToStripe(availability)}>
                        Prenota
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null;
          })}
        </div>
      )}
    </div>
  );
};

export default BookLesson;
