import { useTranslation } from 'react-i18next';
import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ControlledGif from '../components/ControlledGif';
import useAuth from '../hooks/useAuth';
import gif from '../ui/LoopPrincipale.gif';
import gif2 from '../ui/Insegnante.gif';
import piattaforma from '../ui/Piattaforma.gif';
import problemiGif from '../ui/Problemi.gif';
import zainoGif from '../ui/Zaino.gif';
import { ReactComponent as Blob1 } from '../ui/Blob1.svg';
import { ReactComponent as Blob2 } from '../ui/Blob2.svg';
import { ReactComponent as Blob3 } from '../ui/Blob3.svg';
import { ReactComponent as Blob4 } from '../ui/Blob4.svg';
import { ReactComponent as Blob5 } from '../ui/Blob5.svg';
import Insegnante from '../ui/Insegnante.png';
import Piattaforma from '../ui/Piattaforma.png';
import ZainoPng from '../ui/Zaino.png';
import ProblemiPng from '../ui/Problemi.png';

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useAuth();
  // Logica di navigazione condizionale
  useEffect(() => {
    if (auth.isFamily) {
      navigate('/family/home');
    } else if (auth.isTeacher) {
      navigate('/teacher/home');
    }
  }, [auth, navigate]);

  function loginRedirect() {
    navigate('/login');
  }

  return (
    <div className="flex flex-col lg:px-16 gap-y-16 bg-fpcwhite h-auto">
      {/* Hero section */}
      <div className="flex flex-col sm:justify-between sm:flex-row p-4">
        {/*sezione testo*/}
        <div className="flex flex-col gap-y-4 md:justify-center md:items-center ">
          <div className="flex flex-col">
            <h1 className="text-5xl font-bold text-fpcred pb-4 md:text-6xl text-center">
              Ripetizioni
              <span className="text-black"> per tutti i livelli di istruzione</span>
            </h1>

            <h2 className="text-2xl text-black text-center px-16">
              Insegnanti selezionati per garantirti la massima qualità disponibili in pochissimi click!
            </h2>
          </div>

          <div className="flex justify-center items-center p-4 ">
            <button className="p-4 rounded-full shadow-xl bg-fpcred" onClick={loginRedirect}>
              <h2 className="text-white text-xl">Prenota la tua lezione</h2>
            </button>
          </div>
          <div className="p-4 w-full flex justify-center gap-8">
            <h2 className="font-bold text-black text-center">
              <span className="text-3xl">40</span>
              <span className="text-3xl text-fpcred">+</span>
              <br />
              <span>Insegnanti</span>
            </h2>
            <h2 className="font-bold text-black text-center">
              <span className="text-3xl">10</span>
              <span className="text-3xl text-fpcred">K +</span>
              <br />
              <span>Ore di lezione</span>
            </h2>
          </div>
        </div>
        {/*sezione LoopPrincipale*/}
        <div className="relative items-center justify-center flex flex-col sm:flex ">
          <Blob1 className="w-full h-full" />
          <div className="absolute flex items-center justify-center z-10">
            <img src={gif} alt="gif" className="w-4/5 h-4/5" />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center ">
        <h1 className="text-3xl font-bold text-black">
          Affidati ai<span className="text-fpcred"> Professionisti</span>
        </h1>
      </div>
      {/*sezione beneficio 1*/}
      <div className="flex flex-col sm:justify-between gap-y-2 sm:flex-row p-4 ">
        <div className="relative items-center justify-center flex flex-col sm:flex ">
          <Blob2 className="w-full h-full" />
          <div className="absolute flex justify-center items-center z-10">
            <ControlledGif gifSrc={gif2} staticSrc={Insegnante} delay={1000} alt="insegnante" className="w-4/5 h-4/5" />
          </div>
        </div>
        <div className="flex flex-col items-center md:justify-center">
          <h2 className="text-center text-2xl">
            Ogni insegnante è selezionato con attenzione per garantire alle famiglie un servizio di qualità.
            <br />
            <br />
            Ogni studente viene affiancato uno ad uno da un insegnante specializzato, che svolge il lavoro con
            continuità.
          </h2>
        </div>
      </div>
      <div className="flex items-center justify-center ">
        <h1 className="text-3xl font-bold text-black">
          Un servizio <span className="text-fpcred">completo</span>
        </h1>
      </div>
      {/*sezione beneficio 2*/}
      <div className="flex flex-col sm:justify-between gap-y-2 sm:flex-row p-4 ">
        <div className="flex flex-col items-center md:justify-center ">
          <h2 className="text-center text-2xl ">
            La nostra piattaforma è facile da usare e ti permette di prenotare lezioni in pochi click.
            <br />
            <br />
            Ogni insegnante è specializzato in una materia, garantendo un servizio di qualità.
          </h2>
        </div>
        <div className="relative items-center justify-center flex flex-col sm:flex ">
          <Blob3 className="w-full h-full" />
          <div className="absolute flex justify-center items-center z-10">
            <ControlledGif
              gifSrc={piattaforma}
              staticSrc={Piattaforma}
              delay={1000}
              alt="piattaforma"
              className="w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:justify-between gap-y-2 sm:flex-row p-4 ">
        <div className="relative items-center justify-center flex flex-col sm:flex  md:w-1/3">
          <Blob4 className="w-full h-full" />
          <div className="absolute flex justify-center items-center z-10">
            <ControlledGif
              gifSrc={zainoGif}
              staticSrc={ZainoPng}
              delay={1000}
              alt="zainoGif"
              className="w-full h-full"
            />
          </div>
        </div>
        <div className="flex flex-col items-center md:justify-center  md:w-2/3">
          <h2 className="text-center text-2xl md:mx-16 ">
            Pronto per il tuo percorso educativo? Con noi, hai tutto il necessario nel tuo zaino per affrontare ogni
            materia e livello scolastico. Offriamo lezioni personalizzate per supportarti nel raggiungere i tuoi
            obiettivi.
          </h2>
        </div>
      </div>
      <div className="flex flex-col sm:justify-between gap-y-2 sm:flex-row p-4 ">
        <div className="flex flex-col items-center md:justify-center  md:w-2/3">
          <h2 className="text-center text-2xl md:mx-16 ">
            Il nostro team ti permetterà di trovare una soluzione a qualsiasi problema tu possa avere. Siamo qui per te,
            a tua disposizione per aiutarti a superare ogni ostacolo!
          </h2>
        </div>
        <div className="relative items-center justify-center flex flex-col sm:flex  md:w-1/3">
          <Blob5 className="w-full h-full" />
          <div className="absolute flex justify-center items-center z-10">
            <ControlledGif
              gifSrc={problemiGif}
              staticSrc={ProblemiPng}
              delay={1000}
              alt="problemiGif"
              className="w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
