interface SchoolCardProps {
    title: string;
    imageSrc: string;
    onClick?: () => void;
    disabled?: boolean;
}

export const SchoolCardImg: React.FC<SchoolCardProps> = ({ title, imageSrc, onClick, disabled = false }) => {
    function handleClick() {
        if (!disabled && onClick) {
            onClick();
        }
    }
    return (
        <div onClick={handleClick} className={`${disabled ? 'opacity-40 cursor-not-allowed' : 'cursor-pointer transform transition duration-300 hover:scale-105'} flex flex-col items-center justify-center gap-y-5 max-w-xs sm:max-w-sm md:max-w-md rounded-3xl border-b-4 border-fpcred shadow-lg bg-white p-5 sm:p-10 select-none`}>
            <img className="w-full max-h-36 md:max-h-48 object-cover" src={imageSrc} alt={`Immagine della ${title}`} />
            <div className="w-full text-center">
                <p className="font-bold text-lg sm:text-xl">{title}</p>
            </div>
        </div>
    )
}
