import IconProps from '../../interfaces/IconProps';
import { ReactComponent as ActiveDocumentAddIcon } from './assets/act-document-add.svg';
import { ReactComponent as DefaultDocumentAddIcon } from './assets/dis-document-add.svg';
import { ReactComponent as ActiveNotebookSquareIcon } from './assets/act-notebook-square.svg';
import { ReactComponent as DefaultNotebookSquareIcon } from './assets/dis-notebook-square.svg';
import { ReactComponent as ActiveRoutingIcon } from './assets/act-routing.svg';
import { ReactComponent as DefaultRoutingIcon } from './assets/dis-routing.svg';
import { ReactComponent as ActiveUserCheckIcon } from './assets/act-user-check.svg';
import { ReactComponent as DefaultUserCheckIcon } from './assets/dis-user-check.svg';
import { ReactComponent as ActiveUserRoundedIcon } from './assets/act-user-rounded.svg';
import { ReactComponent as DefaultUserRoundedIcon } from './assets/dis-user-rounded.svg';

const DocumentAddIcon: React.FC<IconProps> = (iconProps) => {
  return iconProps.options.activeState ? <ActiveDocumentAddIcon width={iconProps.options.width} height={iconProps.options.height} className={iconProps.options.className} /> : <DefaultDocumentAddIcon width={iconProps.options.width} height={iconProps.options.height} className={iconProps.options.className} />;
};

const NotebookSquareIcon: React.FC<IconProps> = (iconProps) => {
  return iconProps.options.activeState ? <ActiveNotebookSquareIcon width={iconProps.options.width} height={iconProps.options.height} className={iconProps.options.className} /> : <DefaultNotebookSquareIcon width={iconProps.options.width} height={iconProps.options.height} className={iconProps.options.className} />;
};

const RoutingIcon: React.FC<IconProps> = (iconProps) => {
  return iconProps.options.activeState ? <ActiveRoutingIcon width={iconProps.options.width} height={iconProps.options.height} className={iconProps.options.className} /> : <DefaultRoutingIcon width={iconProps.options.width} height={iconProps.options.height} className={iconProps.options.className} />;
};

const UserCheckIcon: React.FC<IconProps> = (iconProps) => {
  return iconProps.options.activeState ? <ActiveUserCheckIcon width={iconProps.options.width} height={iconProps.options.height} className={iconProps.options.className} /> : <DefaultUserCheckIcon width={iconProps.options.width} height={iconProps.options.height} className={iconProps.options.className} />;
};

const UserRoundedIcon: React.FC<IconProps> = (iconProps) => {
  return iconProps.options.activeState ? <ActiveUserRoundedIcon width={iconProps.options.width} height={iconProps.options.height} className={iconProps.options.className} /> : <DefaultUserRoundedIcon width={iconProps.options.width} height={iconProps.options.height} className={iconProps.options.className} />;
};

const renderIcon = (iconProps: IconProps) => {
    const { iconName } = iconProps;
    const { width, height, activeState, className } = iconProps.options;

    switch (iconName) {
      case 'document-add':
        return <DocumentAddIcon  options={{width: width, height: height, activeState: activeState, className: className}}/>;
      case 'notebook-square':
        return <NotebookSquareIcon options={{width: width, height: height, activeState: activeState, className: className}} />;
      case 'routing':
        return <RoutingIcon options={{width: width, height: height, activeState: activeState, className: className}} />;
      case 'user-check':
        return <UserCheckIcon options={{width: width, height: height, activeState: activeState, className: className}} />;
      case 'user-rounded':
        return <UserRoundedIcon options={{width: width, height: height, activeState: activeState, className: className}} />;
      default:
        return null;
    }
};

const Icon: React.FC<IconProps> = (iconProps) => {
  return renderIcon(iconProps);
};

export default Icon;
