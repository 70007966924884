import React from 'react';
import { useState, useEffect } from 'react';
import { EventInput } from '@fullcalendar/core';
interface Props {
  selectedEvent: EventInput;
  closeModal: () => void;
}

const EditLessonModal: React.FC<Props> = ({ selectedEvent, closeModal }) => {
  // Inserisci qui la logica del tuo componente

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center">
      <div className="bg-white p-4 mx-auto w-96 h-48 rounded-lg shadow-lg flex flex-col">
        <div className="flex justify-between">
          <h1 className="text-fpcred font-bold text-xl">Modifica la lezione</h1>
          <button onClick={closeModal}>
            <p className="text-fpcred font-bold text-xl">X</p>
          </button>
        </div>
        <div className="flex flex-1">
          <p>Info...</p>
        </div>
        <div className="flex justify-center">
            <button className="bg-fpcred text-white font-bold rounded-lg w-1/3 p-2">Disdici</button>
        </div>

      </div>
    </div>
  );
};

export default EditLessonModal;
