import React, { useState, useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

function ControlledGif({ gifSrc, staticSrc, delay = 0, className = '', alt = 'gif' }) {
  const [imgSrc, setImgSrc] = useState(staticSrc || gifSrc);
  const timeoutRef = useRef(null);

  const { ref: imgInviewRef, inView: imgInView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    // Cancella sempre il timeout esistente prima di impostarne uno nuovo
    clearTimeout(timeoutRef.current);

    if (imgInView) {
      timeoutRef.current = setTimeout(() => {
        setImgSrc(`${gifSrc}?timestamp=${new Date().getTime()}`);
      }, delay);
    } else {
      setImgSrc(staticSrc || gifSrc);
    }

    return () => {
      // Pulizia del timeout quando il componente si smonta o la dipendenza cambia
      clearTimeout(timeoutRef.current);
    };
  }, [imgInView, gifSrc, staticSrc, delay]);

  return (
    <img ref={imgInviewRef} src={imgSrc} alt={alt} className={className} />
  );
}

export default ControlledGif;
