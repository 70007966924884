import { useState, useEffect } from 'react';

function useScreenWidth() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial width

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return screenWidth;
}

export default useScreenWidth;
