import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";

const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === null) {
      throw new Error("useAuth deve essere usato all'interno di un AuthProvider");
    }
    return context;
  };

export default useAuth;
