import React from 'react';
import { useState } from 'react';
import { ProfileComponent } from '../../pages/teacher/Profile';

interface ProfileGridProps {
  goTo: (component: ProfileComponent) => void;
}

const TeacherProfileGrid: React.FC<ProfileGridProps> = ({ goTo }) => {
  return (
    <div className="h-96 lg:h-full w-96 grid grid-cols-2 gap-4">
      <div
        className="w-full rounded-xl bg-white flex justify-center items-end p-4 text-sm text-center font-bold shadow-md cursor-pointer"
        onClick={() => goTo('EditProfile')}>
        <p>Informazioni personali</p>
      </div>
      <div
        className="w-full rounded-xl bg-white flex justify-center items-end p-4 text-sm text-center font-bold shadow-md cursor-pointer"
        onClick={() => goTo('EditTaxInformation')}>
        <p>Informazioni di fatturazione</p>
      </div>
      <div className="w-full rounded-xl bg-white flex justify-center items-end p-4 text-sm text-center font-bold shadow-md cursor-pointer opacity-50">
        {/*onClick={() => goTo('ChangePassword')}*/}
        <p>Modifica password</p>
      </div>
      <div
        className="w-full rounded-xl bg-white flex justify-center items-end p-4 text-sm text-center font-bold shadow-md cursor-pointer opacity-50">
        {/*onClick={() => goTo('DeleteAccount')}*/}
        <p>Cancella account</p>
      </div>
    </div>
  );
};

export default TeacherProfileGrid;
