import React from 'react';
import { TeacherForm } from '../../model/registration/TeacherForm';
import { City, Province } from '../../model/TeacherInfo';
import { VatType } from '../../model/VatType';

interface Props {
  form: TeacherForm;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  cities: City[];
  provinces: Province[];
  errors: { [key: string]: string };
}

const InvoiceInfo: React.FC<Props> = ({ form, handleChange, cities, provinces, errors }) => {
  return (
    <div className="bg-white p-4 flex flex-col rounded-lg shadow-lg gap-4">
      <p className="text-center text-xl text-fpcred font-bold">Informazioni di fatturazione</p>
      <form className="flex flex-col gap-2">
        <div className="lg:flex-row flex flex-col gap-2 justify-between">
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Partita Iva</span>
            <input
              type="text"
              className={`border ${errors.vat ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="vat"
              value={form.vat}
              onChange={handleChange}
            />
            {errors.vat && <span className="text-red-500">{errors.vat}</span>}
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Tipo partita Iva</span>
            <select
              className="border border-gray-400 rounded-lg p-2"
              name="vat_type"
              value={form.vat_type}
              onChange={handleChange}>
              <option value={VatType.FORFETTARIO}>Forfettario</option>
              <option value={VatType.FORFETTARIO_STARTUP}>Forfettario startup</option>
              <option value={VatType.ORDINARIO}>Ordinario</option>
              <option value={VatType.ALTRO}>Altro</option>
            </select>
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Codice destinatario</span>
            <input
              type="text"
              className={`border ${errors.destination_id ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="destination_id"
              value={form.destination_id}
              onChange={handleChange}
            />
            {errors.destination_id && <span className="text-red-500">{errors.destination_id}</span>}
          </label>
        </div>
        <div className="lg:flex-row flex flex-col gap-2">
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Provincia</span>
            <select
              name="invoice_province_id"
              value={form.invoice_province_id}
              onChange={handleChange}
              className="w-full border border-gray-400 rounded-lg px-2 text-lg py-2">
              <option value={0} disabled>
                Seleziona una provincia
              </option>
              {provinces.map((province) => (
                <option key={province.id} value={province.id}>
                  {province.name}
                </option>
              ))}
            </select>
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Città</span>
            <select
              disabled={cities.length === 0}
              name="invoice_city_id"
              value={form.invoice_city_id}
              onChange={handleChange}
              className="w-full border border-gray-400 rounded-lg px-2 text-lg py-2">
              <option value={0} disabled>
                Seleziona una città
              </option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </label>
          <label className="flex flex-col gap-2 w-full">
            <span className="font-bold text-xl sm:text-lg">Cap</span>
            <input
              className="border border-gray-400 rounded-lg p-2"
              name="invoice_zip"
              value={form.invoice_zip}
              type="text"
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="flex gap-2">
          <label className="flex flex-col gap-2 w-3/4">
            <span className="font-bold text-xl sm:text-lg">Indirizzo</span>
            <input
              type="text"
              className="border border-gray-400 rounded-lg p-2"
              name="invoice_street_address"
              value={form.invoice_street_address}
              onChange={handleChange}
            />
          </label>
          <label className="flex flex-col gap-2 w-1/4">
            <span className="font-bold text-xl sm:text-lg">Civico</span>
            <input
              type="text"
              className="border border-gray-400 rounded-lg p-2"
              name="invoice_street_number"
              value={form.invoice_street_number}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="flex flex-col gap-2">
          <label className="flex flex-col gap-2 ">
            <span className="font-bold text-xl sm:text-lg">Iban</span>
            <input
              type="text"
              className={`border ${errors.iban ? 'border-red-500' : 'border-gray-400'} rounded-lg p-2`}
              name="iban"
              value={form.iban}
              onChange={handleChange}
            />
            {errors.iban && <span className="text-red-500">{errors.iban}</span>}
          </label>
        </div>
      </form>
      <p>Continuando, dichiari di accettare i termini e condizioni indicate di seguito: </p>
    </div>
  );
};

export default InvoiceInfo;
