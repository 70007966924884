import React from 'react';
import { Card } from '../Card'; // Assumendo l'esistenza di questo componente
import { School } from '../../model/registration/SchoolSelection';

interface SchoolAddressSelectionProps {
  options: School[];
  onSchoolAddressSelection: () => void;
  setSelectedSchoolAddress: (selectedAddress: string) => void;
}

const SchoolAddressSelection: React.FC<SchoolAddressSelectionProps> = ({
  options,
  onSchoolAddressSelection,
  setSelectedSchoolAddress,
}) => {
  function onClick(selectedAddress: string) {
    setSelectedSchoolAddress(selectedAddress);
    onSchoolAddressSelection();
  }

  return (
    <div className="flex flex-col items-center justify-center gap-y-4">
      <h2 className="text-2xl font-bold mb-4">Quale indirizzo frequenta?</h2>
      <div className="flex flex-wrap gap-4 sm:gap-6 md:gap-8 lg:gap-10 justify-center md:max-w-3xl">
        {options.map((option, index) => (
          <Card key={index} title={option.name} onClick={() => onClick(option.name)} />
        ))}
      </div>
    </div>
  );
};

export default SchoolAddressSelection;
