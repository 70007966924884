import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';

interface Props {
  type: 'active' | 'passive';
}

interface Invoice {
  client: {
    cf: string;
    city: string;
    company_name: string;
    first_name: string;
    id: number;
    last_name: string;
    province: string;
    street_name: string;
    street_number: string;
    vat_number: string;
    zip: string;
  };
  created_at: string;
  description: string;
  id: number;
  number: number;
  provider: { id: number };
  status: string;
  subtotal: number;
  uuid: string;
}

const Invoices: React.FC<Props> = ({ type }) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalInvoices, setTotalInvoices] = useState(1);
  const { setLoading } = useLoading();
  const { setError } = useError();

  useEffect(() => {
    setLoading(true);
    api
      .get(`/invoice/${type}?page=${currentPage}`)
      .then((response) => {
        console.log(response.data);
        setInvoices(response.data.invoices);
        setTotalInvoices(response.data.pagination.total);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [type, currentPage]);

  const getPdfInvoice = (id: number) => {
    console.log(`Scaricando PDF per fattura ID: ${id}`);
    setLoading(true);
    api
      .get(`invoice/${id}/pdf`, { responseType: 'blob' })
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
      })
      .catch((error) => {
        console.error('error is:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getXmlInvoice = (id: number) => {
    console.log(`Scaricando XML per fattura ID: ${id}`);
    setLoading(true);
    api
      .get(`invoice/${id}/xml`)
      .then((response) => {
        const xmlBlob = new Blob([response.data], { type: 'application/xml' });
        const xmlUrl = URL.createObjectURL(xmlBlob);
        window.open(xmlUrl, '_blank');
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeModal = () => {
    setSelectedInvoice(null);
  };

  if (invoices.length === 0) {
    return (
      <div className="bg-white rounded-xl p-4 max-h-[500px] max-w-full overflow-auto">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          {type === 'active' ? 'Fatture inviate' : 'Fatture ricevute'}
        </h2>
        <p className="text-lg text-gray-800">Non ci sono fatture da mostrare.</p>
      </div>
    );
  }

  return (
    <div className="w-full h-full overflow-auto flex flex-col gap-y-4 max-h-[600px]">
      <h2 className="text-fpcred text-2xl font-bold">{type === 'active' ? 'Fatture inviate' : 'Fatture ricevute'}</h2>
      <div className="overflow-auto pb-4 border-4 border-gray-500 border-opacity-50 rounded-lg">
        <table className="min-w-full bg-white">
          <thead className="sticky top-0 bg-white">
            <tr>
              <th className="py-2 px-4 border-b">Numero Fattura</th>
              <th className="py-2 px-4 border-b">Nome Cliente</th>
              <th className="py-2 px-4 border-b">Nome Azienda</th>
              <th className="py-2 px-4 border-b">Descrizione</th>
              <th className="py-2 px-4 border-b">Stato</th>
              <th className="py-2 px-4 border-b">Subtotale</th>
              <th className="py-2 px-4 border-b">Creata il</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr
                key={invoice.id}
                className="cursor-pointer hover:bg-gray-100"
                onClick={() => setSelectedInvoice(invoice)}>
                <td className="py-2 px-4 border-b">{invoice.number}</td>
                <td className="py-2 px-4 border-b">{`${invoice.client.first_name} ${invoice.client.last_name}`}</td>
                <td className="py-2 px-4 border-b">{invoice.client.company_name}</td>
                <td className="py-2 px-4 border-b">{invoice.description}</td>
                <td className="py-2 px-4 border-b">{invoice.status}</td>
                <td className="py-2 px-4 border-b">{invoice.subtotal.toFixed(2)}</td>
                <td className="py-2 px-4 border-b">{new Date(invoice.created_at).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedInvoice && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center" onClick={closeModal}>
          <div className="bg-white rounded-lg p-6 w-96 relative" onClick={(e) => e.stopPropagation()}>
            <button className="absolute top-2 right-2 text-gray-600 hover:text-gray-800" onClick={closeModal}>
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <h3 className="text-xl font-bold mb-4">Fattura {selectedInvoice.number}</h3>
            {selectedInvoice.client.first_name ||
              (selectedInvoice.client.last_name && (
                <p className="mb-4">
                  Cliente: {`${selectedInvoice.client.first_name} ${selectedInvoice.client.last_name}`}
                </p>
              ))}
            {selectedInvoice.client.company_name && (
              <p className="mb-4">Azienda: {selectedInvoice.client.company_name}</p>
            )}
            <p className="mb-4">Descrizione: {selectedInvoice.description}</p>
            <p className="mb-4">Subtotale: {selectedInvoice.subtotal.toFixed(2)} €</p>
            <div className="flex justify-end space-x-4">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={() => getPdfInvoice(selectedInvoice.id)}>
                Visualizza PDF
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={() => getXmlInvoice(selectedInvoice.id)}>
                Visualizza XML
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center mb-4">
        <button
          className={`px-4 py-2 flex items-center ${currentPage === 1 ? 'cursor-not-allowed text-gray-300' : 'cursor-pointer text-black'}`}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}>
          &lt;
        </button>

        <span className="text-black flex items-center px-2">
          Pagina {currentPage} di {Math.ceil(totalInvoices / 20)}
        </span>

        <button
          className={`px-4 py-2 flex items-center ${currentPage === Math.ceil(totalInvoices / 20) ? 'cursor-not-allowed text-gray-300' : 'cursor-pointer text-black'}`}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalInvoices))}
          disabled={currentPage === Math.ceil(totalInvoices / 20)}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Invoices;
