import React, { useState, useEffect } from 'react';
import { ProfileComponent } from '../../pages/teacher/Profile';
import { City, TeacherInfo, Province } from '../../model/TeacherInfo';
import api from '../../utils/api';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';

interface EditProfileProps {
  goTo: (component: ProfileComponent) => void;
}

interface Form {
  first_name: string;
  last_name: string;
  phone: string;
  cf: string;
  province_id: number;
  city_id: number;
  zip: string;
  street_name: string;
  street_number: string;
}

const EditProfile: React.FC<EditProfileProps> = ({ goTo }) => {
  const [teacherInfo, setTeacherInfo] = useState<TeacherInfo>();
  const [cities, setCities] = useState<City[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [form, setForm] = useState<Form>({
    first_name: '',
    last_name: '',
    phone: '',
    cf: '',
    province_id: 0,
    city_id: 0,
    zip: '',
    street_name: '',
    street_number: '',
  });
  const { setLoading } = useLoading();
  const { setError } = useError();

  useEffect(() => {
    setLoading(true);
    api.get('/teacher')
      .then((response) => {
        const teacher = response.data.user;
        setTeacherInfo(teacher);
        setForm({
          first_name: teacher.first_name,
          last_name: teacher.last_name,
          phone: teacher.phone,
          cf: teacher.cf,
          province_id: teacher.province.id,
          city_id: teacher.city.id,
          zip: teacher.zip,
          street_name: teacher.street_name,
          street_number: teacher.street_number,
        });
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    api.get('/province?page_size=200')
      .then((response) => {
        setProvinces(response.data.provinces);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (form.province_id) {
      setLoading(true);
      api.get(`/city?province_id=${form.province_id}&page_size=200`)
        .then((response) => {
          setCities(response.data.cities);
        })
        .catch((error) => {
          console.error(error);
          setError(true, error.response.data.message, error.response.status);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [form.province_id]);

  function onSave(component: ProfileComponent) {
    setLoading(true);
    setForm((prev) => ({ ...prev, city_id: Number(form.city_id), province_id: Number(form.province_id) }));
    api
      .patch('/teacher', form)
      .then(() => {
        goTo(component);
      })
      .catch((error) => {
        console.error('Errore durante il salvataggio:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  }

  if (!teacherInfo) {
    return null; // Ritorna null se teacherInfo non è ancora stato caricato
  }

  return (
    <div className="flex flex-col p-4 gap-4 bg-white rounded-lg shadow-md">
      <div className="font-bold text-center">
        <h2>Informazioni personali</h2>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col">
          <div className="text-fpcred font-bold">Nome</div>
          <input
            className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
            name="first_name"
            value={form.first_name}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-fpcred font-bold">Cognome</div>
          <input
            className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
            name="last_name"
            value={form.last_name}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-fpcred font-bold">Cellulare</div>
          <input
            className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
            name="phone"
            value={form.phone}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-fpcred font-bold">Codice fiscale</div>
          <input
            className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
            name="cf"
            value={form.cf}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-around gap-2">
          <div className="flex flex-col">
            <div className="text-fpcred font-bold">Provincia</div>
            <select
              name="province_id"
              value={form.province_id}
              onChange={handleChange}
              className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
            >
              <option value="" disabled>
                Seleziona una provincia
              </option>
              {provinces.map((province) => (
                <option key={province.id} value={province.id}>
                  {province.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <div className="text-fpcred font-bold">Città</div>
            <select
              name="city_id"
              value={form.city_id}
              onChange={handleChange}
              className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
            >
              <option value={0} disabled>
                Seleziona una città
              </option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="w-full flex flex-col">
          <div className="text-fpcred font-bold">Cap</div>
          <input
            className="w-full border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
            name="zip"
            value={form.zip}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-row gap-2">
          <div className="w-3/4 flex flex-col">
            <div className="text-fpcred font-bold">Indirizzo</div>
            <input
              className="w-full border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
              name="street_name"
              value={form.street_name}
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="w-1/4 flex flex-col">
            <div className="text-fpcred font-bold">Numero</div>
            <input
              className="w-full border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
              name="street_number"
              value={form.street_number}
              type="text"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4 justify-center">
        <button className="bg-fpcred text-white rounded-lg p-2 w-1/2" onClick={() => goTo('ProfileGrid')}>
          Indietro
        </button>
        <button className="bg-fpcred text-white rounded-lg p-2 w-1/2" onClick={() => onSave('ProfileGrid')}>
          Salva
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
