import React, { useEffect, useState } from 'react';
import TeacherProfileGrid from '../../components/teacher/TeacherProfileGrid';
import EditPersonalProfile from '../../components/teacher/EditPersonalProfile';
import EditTaxInformation from '../../components/teacher/EditTaxInformation';
import ChangePassword from '../../components/teacher/ChangePassword';
import DeleteAccount from '../../components/teacher/DeleteAccount';
import api from '../../utils/api';
import { TeacherInfo } from '../../model/TeacherInfo';
import { ReactComponent as ImmagineDiProva } from '../../ui/ImmagineDiProva.svg';
import withAuth from '../../utils/IsAuth';
import useAuth from '../../hooks/useAuth';

export type ProfileComponent =
  | 'ProfileGrid'
  | 'EditProfile'
  | 'ChangePassword'
  | 'DeleteAccount'
  | 'EditTaxInformation';

const TeacherProfile: React.FC = () => {
  const [activeComponent, setActiveComponent] = useState<ProfileComponent>('ProfileGrid');
  const [teacherInfo, setTeacherInfo] = useState<TeacherInfo>();
  const { auth, logout } = useAuth();

  useEffect(() => {
    api.get('/teacher').then((response) => {
      setTeacherInfo(response.data.user);
    });
  }, []);

  const handleShowComponent = (component: ProfileComponent) => {
    setActiveComponent(component);
  };

  if (!teacherInfo) return null;

  return (
    <div className="w-full h-full flex flex-col items-center gap-y-4">
      {/* notifiche */}
      <div className="p-4 flex w-full justify-between bg-fpcred rounded-lg">
        <div>
          <h1 className="text-xl font-bold text-white">Il tuo profilo</h1>
        </div>
        <div>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17.0001M15 17L9 17.0001M15 17H19C19.5523 17 20 16.5523 20 16V15.4141C20 15.1489 19.8945 14.8946 19.707 14.707L19.1963 14.1963C19.0706 14.0706 19 13.9 19 13.7222V10C19 9.82357 18.9936 9.64855 18.9805 9.4761M9 17.0001L5 17.0001C4.44772 17.0001 4 16.5521 4 15.9998V15.4141C4 15.1489 4.10544 14.8949 4.29297 14.7073L4.80371 14.1958C4.92939 14.0701 5 13.9002 5 13.7224V9.99998C5 6.13401 8.134 3 12 3C12.7116 3 13.3984 3.10618 14.0454 3.30357M18.9805 9.4761C20.1868 8.7873 21 7.48861 21 6C21 3.79086 19.2091 2 17 2C15.8298 2 14.7769 2.50253 14.0454 3.30357M18.9805 9.4761C18.3966 9.80949 17.7205 10 17 10C14.7909 10 13 8.20914 13 6C13 4.9611 13.3961 4.0147 14.0454 3.30357M18.9805 9.4761C18.9805 9.47609 18.9805 9.4761 18.9805 9.4761ZM14.0454 3.30357C14.0459 3.30371 14.0464 3.30385 14.0468 3.304"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>

      {/* immagine e nome */}
      <div className="flex flex-col w-full gap-4 sm:flex-row overflow-auto">
        <div className="flex flex-col w-full gap-4">
          <div className="flex gap-4 sm:max-w p-2 sm:p-4 bg-white rounded-xl shadow-md">
            <div className="flex w-full">
              <div className='flex flex-col'>
              <div className="h-full">
                <ImmagineDiProva className="w-full h-full" />
              </div>
              {auth.isAuth && (
                <div onClick={logout} className="flex flex-row items-center mb-10 justify-center cursor-pointer">
                  <img
                    src="https://icongr.am/material/logout.svg?size=64&color=FF5757"
                    alt="logout-icon"
                    className="w-8 h-8"
                  />
                  <span className="text-red-500 font-bold">Logout</span>
                </div>
              )}
            </div>

              <div className="w-2/3 items-center justify-center flex flex-col gap-y-2 p-4">
                <h1 className="text-xl font-bold text-fpcred">
                  {teacherInfo.first_name} {teacherInfo.last_name}
                </h1>
                <h2 className="text-lg text-black">{teacherInfo.email}</h2>
                <div>
                  <h3 className="text-lg font-bold">Materie abilitate:</h3>
                </div>
                <div className="flex text-xl font-bold gap-4">
                  {teacherInfo.subjects.map((subject, index) => (
                    <div key={index}>{subject.name}</div>
                  ))}
                </div>
                <div className="flex flex-col w-full bg-white rounded-lg p-2">
                  <div className="text-center font-bold">Presentazione</div>
                  <div className="border-2 border-fpcred h-48 rounded-lg">
                    <textarea
                      className="w-full text-start h-full font-bold rounded-lg"
                      placeholder="Scrivi qui"
                      defaultValue={teacherInfo.bio || ''}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center w-full">
          {activeComponent === 'ProfileGrid' && <TeacherProfileGrid goTo={handleShowComponent} />}
          {activeComponent === 'EditProfile' && <EditPersonalProfile goTo={handleShowComponent} />}
          {activeComponent === 'EditTaxInformation' && <EditTaxInformation goTo={handleShowComponent} />}
          {activeComponent === 'ChangePassword' && <ChangePassword goTo={handleShowComponent} />}
          {activeComponent === 'DeleteAccount' && <DeleteAccount goTo={handleShowComponent} />}
        </div>
      </div>
    </div>
  );
};

export default withAuth(TeacherProfile);
