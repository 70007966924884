interface NavbarHamburgerProps {
    isOpen: boolean; // Definizione della prop isOpen come boolean
}

function NavbarHamburger({ isOpen }: NavbarHamburgerProps) {
    /*
    NavbarHamburger is the menu icon for mobile brochure website
    */
    return (
        < svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            className="fill-current text-fpcred"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0"
                y="12" // 12px is half of the height of the SVG + height/2 because the 0 point is on top of the SVG
                width="22"
                height="2"
                rx="1"
                className={`transform transition-all duration-300 origin-center ${isOpen ? '-rotate-45 -translate-y-[1.5px] -translate-x-[1.5px]' : 'rotate-0'}`} // Translation of -1.5px to compensate for the rotation
            />
            <rect
                x="0"
                y="8"
                width="22"
                height="2"
                rx="1"
                className={`transform transition-all duration-300 origin-center ${isOpen ? 'rotate-45 translate-y-[1.5px] -translate-x-[1.5px]' : 'rotate-0'}`}
            />
        </svg >
    )
}

export default NavbarHamburger;