import { useTranslation } from "react-i18next";

function Pricing() {
    const { t } = useTranslation();
    return (
        <>
            <div className="container mx-auto text-center p-4">
                <h1 className="text-3xl font-bold mb-4">{t("pricingTitle")}</h1>
                <p className="text-lg">{t("pricingDescription")}</p>
            </div>
        </>
    )
}

export default Pricing