import React, { useState } from 'react';
import axios from 'axios';
import { useError } from '../../context/ErrorContext';
import { useLoading } from '../../context/LoadingContext';
import api from '../../utils/api';
import useAuth from '../../hooks/useAuth';
import withAuth from '../../utils/IsAuth';

const AdminHome: React.FC = () => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { error, setError } = useError();
  const { setLoading } = useLoading();
  const { auth, logout } = useAuth();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSuccessMessage('');

    try {
      const response = await api.post('/auth/teacher-token', { email });
      setToken(response.data.token);
      setSuccessMessage('Token creato con successo!');
    } catch (error) {
      setError(true, error.response.data.message, error.response.status);
    }
  };

  return (
    <div>
      <h1>Admin Home</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" value={email} onChange={handleEmailChange} required />
        </div>
        <button type="submit">Crea Token Insegnante</button>
      </form>
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      {token && (
        <div>
          <h2>Token Insegnante</h2>
          <p>{token}</p>
        </div>
      )}
      {error && <p style={{ color: 'red' }}>{error.message}</p>}
      <div className="flex border-2 justify-center">
        {auth.isAuth && (
          <div onClick={logout} className="flex flex-row items-center mb-10 justify-center cursor-pointer">
            <img
              src="https://icongr.am/material/logout.svg?size=64&color=FF5757"
              alt="logout-icon"
              className="w-8 h-8"
            />
            <span className="text-red-500 font-bold">Logout</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default withAuth(AdminHome);
