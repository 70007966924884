import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { useLocation } from 'react-router-dom';
import { useLoading } from '../context/LoadingContext';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [view, setView] = useState<'email' | 'code' | 'password' | 'outcome'>();
  const [token, setToken] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [outcomeMessage, setOutcomeMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenParam = queryParams.get('token');
  const { setLoading } = useLoading();

  useEffect(() => {
    if (tokenParam) {
      setView('password');
      setToken(tokenParam);
    } else {
      setView('email');
    }
  }, [tokenParam]);

  function forgotPassword() {
    setLoading(true);
    api
      .post('auth/password-reset-request', { email: email })
      .then((res) => {
        setView('code');
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function validatePassword(password: string): boolean {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(password);
  }

  function resetPassword() {
    if (password !== confirmPassword) {
      setErrorMessage('Le password non corrispondono.');
      return;
    }

    if (!validatePassword(password)) {
      setErrorMessage('La password deve contenere almeno 8 caratteri, con almeno una lettera maiuscola, una minuscola e un numero.');
      return;
    }

    setErrorMessage('');

    setLoading(true);
    api
      .post('auth/reset-password', { token: token, password: password })
      .then((res) => {
        setView('outcome');
        setOutcomeMessage('Password cambiata con successo!');
      })
      .catch((err) => {
        console.log(err);
        setView('outcome');
        setOutcomeMessage('Errore durante il cambio della password!');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="w-full h-full flex justify-center items-center">
      {view === 'email' && (
        <div className="py-5 px-8 bg-white rounded-3xl shadow-xl max-w-3xl flex flex-col gap-4 items-center justify-center">
          <h2 className="text-center text-2xl font-bold">Password dimenticata</h2>
          <p className="text-center text-xl">
            Inserisci la tua email e ti invieremo un link per reimpostare la password
          </p>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email"
            className="border py-2.5 px-5 rounded-3xl text-xl font-normal placeholder:text-[#8A8A8A] placeholder:font-normal"
          />
          <button className="bg-red-500 text-white px-4 py-2 rounded max-w-xs" onClick={forgotPassword}>
            Invia
          </button>
        </div>
      )}
      {view === 'code' && (
        <div className="py-5 px-8 bg-white rounded-3xl shadow-xl max-w-3xl flex flex-col gap-4 items-center justify-center">
          <h2 className="text-center text-2xl font-bold">
            Riceverai un link all'email che ci hai fornito per recuperare la password!
          </h2>
        </div>
      )}
      {view === 'password' && (
        <div className="py-5 px-8 bg-white rounded-3xl shadow-xl max-w-3xl flex flex-col gap-4 items-center justify-center">
          <h2 className="text-center text-2xl font-bold">Inserisci la nuova password</h2>
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Nuova password"
            className="border py-2.5 px-5 rounded-3xl text-xl font-normal placeholder:text-[#8A8A8A] placeholder:font-normal"
          />
          <input
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            placeholder="Conferma password"
            className="border py-2.5 px-5 rounded-3xl text-xl font-normal placeholder:text-[#8A8A8A] placeholder:font-normal"
          />
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <button className="bg-red-500 text-white px-4 py-2 rounded max-w-xs" onClick={resetPassword}>
            Invia
          </button>
        </div>
      )}
      {view === 'outcome' && (
        <div className="py-5 px-8 bg-white rounded-3xl shadow-xl max-w-3xl flex flex-col gap-4 items-center justify-center">
          <h2 className="text-center text-2xl font-bold">{outcomeMessage}</h2>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
