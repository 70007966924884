import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { useLoading } from '../context/LoadingContext';

const RegistrationConfirm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [confirmationStatus, setConfirmationStatus] = useState<'loading' | 'success' | 'error'>('loading');
    const { setLoading } = useLoading();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (token) {
            setLoading(true);
            api.post('/auth/family-confirm', { token: token })
                .then((response) => {
                    if (response.status === 200 && response.data.status === "ok") {
                        setConfirmationStatus('success');
                        setTimeout(() => {
                            localStorage.removeItem('registrationForm');
                            navigate('/login');
                        }, 5000);
                    } else {
                        setConfirmationStatus('error');
                    }
                })
                .catch(() => {
                    setConfirmationStatus('error');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            console.error('No token provided');
            setConfirmationStatus('error');
        }
    }, [location, navigate, setLoading]);

    return (
        <div className="flex flex-col items-center min-h-screen justify-center">
            <h1 className="text-3xl font-bold mb-4">Conferma Email</h1>
            {confirmationStatus === 'success' ? (
                <>
                    <p className="text-green-500">Email confermata con successo!</p>
                    <p className="text-gray-500">Verrai reindirizzato automaticamente alla pagina di login tra 5 secondi...</p>
                </>
            ) : confirmationStatus === 'error' ? (
                <p className="text-red-500">Errore nella conferma dell'email. Riprova più tardi.</p>
            ) : (
                <p className="text-gray-500">Conferma dell'email in corso...</p>
            )}
        </div>
    );
};

export default RegistrationConfirm;
