interface UserCircleProps {
    isOpen: boolean; // Definizione della prop isOpen come boolean
    mouseHover: string;
    openPar: string;
}

function UserCircle({ isOpen, mouseHover, openPar }: UserCircleProps) {
    /*
    UserCircle is the login icon when logged
    */

    var hoverParameters: string = "";
    for (var par of mouseHover.split(' ')) {
        hoverParameters += 'hover:' + par + ' ';
    }
    // var openParameters: string = "";
    // for (var par of openPar.split(' ')) {
    //     openParameters += 'action:' + par + ' ';
    // }
    return (
        < svg
            width="28"
            height="28"
            viewBox="0 0 24 24"
            className={`fill-none stroke-grey1 transition-all duration-150 ${!isOpen ? hoverParameters : ''} ${isOpen ? openPar : ''}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                id="Vector"
                d="M17.2166 19.3323C15.9349 17.9008 14.0727 17 12 17C9.92734 17 8.06492 17.9008 6.7832 19.3323M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg >
    )
}

// Impostazione delle default props per il componente
UserCircle.defaultProps = {
    mouseHover: '',
    openPar: ''
};

export default UserCircle;