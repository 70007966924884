import { useTranslation } from "react-i18next";

function NotFound() {
    const { t } = useTranslation();
    return (
        <>
            <div className="container mx-auto text-center p-4">
                <h1 className="text-4xl font-bold mb-4">{t("notFoundTitle")}</h1>
                <p className="text-lg">{t("notFoundDescription")}</p>
            </div>
        </>
    )
}

export default NotFound