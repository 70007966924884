import { createContext, useEffect, useState } from 'react';
import api from '../utils/api';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { LoginType } from '../pages/Login';
import { AxiosResponse } from 'axios';
import CryptoJS from 'crypto-js';
import { useLoading } from './LoadingContext';
import { useError } from './ErrorContext';

interface Authorization {
  token?: string;
  refreshToken?: string;
  isAuth?: boolean;
  isAdmin?: boolean;
  isFamily?: boolean;
  isTeacher?: boolean;
}

interface AuthContextType {
  auth: Authorization;
  login: (email: string, password: string, category: LoginType) => Promise<void>;
  logout: () => void;
  register: (request: any, category: 'family' | 'teacher' | 'admin') => Promise<AxiosResponse<any, any>>;
}

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY!;

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: any) => {
  const [auth, setAuth] = useState<Authorization>({});
  const [expirationToken, setExpirationToken] = useState<number | null>(null);
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { setError } = useError();

  useEffect(() => {
    if (!expirationToken) return;
    if (expirationToken < Date.now()) {
      logout();
    } else {
      const timeoutId = setTimeout(() => {
        createNewToken(auth.refreshToken);
      }, expirationToken - Date.now());

      return () => clearTimeout(timeoutId); // Cleanup timeout on component unmount
    }
  }, [expirationToken, auth.refreshToken]);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const encryptedRoles = localStorage.getItem('roles');
    let roles = null;
    if (encryptedRoles) {
      roles = JSON.parse(CryptoJS.AES.decrypt(encryptedRoles, encryptionKey).toString(CryptoJS.enc.Utf8));
    }

    if (token) {
      const decodedToken: any = jwtDecode(token);
      if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
        console.log('Token expired');
        if (!refreshToken) {
          logout();
        } else {
          createNewToken(refreshToken);
        }
      } else {
        setAuth({ token: token, refreshToken: refreshToken, isAuth: true, ...roles });
      }
    } else if (refreshToken) {
      createNewToken(refreshToken);
    }
  }, []);

  useEffect(() => {
    if (auth.isAuth === undefined) return;
    if (auth.isAuth) {
      if (auth.token) {
        verifyToken(auth.token);
      } else {
        createNewToken(auth.refreshToken || localStorage.getItem('refreshToken'));
      }
    } else {
      localStorage.removeItem('authToken');
    }
  }, [auth]);

  const verifyToken = (token: string) => {
    const decodedJWT: any = jwtDecode(token);
    if (decodedJWT && decodedJWT.exp) {
      setExpirationToken(decodedJWT.exp * 1000);
    }
  };

  const storeAuthData = (token: string, refreshToken: string, roles: object) => {
    const encryptedRoles = CryptoJS.AES.encrypt(JSON.stringify(roles), encryptionKey).toString();
    localStorage.setItem('authToken', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('roles', encryptedRoles);
    setAuth({ token, refreshToken, isAuth: true, ...roles });
  };

  const login = async (email: string, password: string, category: LoginType) => {
    setLoading(true);
    try {
      const response = await api.post('/auth/signin', { email, password, category });
      if (response.status === 200) {
        const { token, refresh_token: refreshToken } = response.data;
        const roles = { isAdmin:category === "admin", isFamily: category === 'family', isTeacher: category === 'teacher' };
        console.log(roles);
        storeAuthData(token, refreshToken, roles);
        if(category === 'admin') {
          navigate('/admin');
          return;
        }
        navigate('/');
      }
    } catch (error) {
      setError(true, error.response.data.message, error.response.status);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('roles');
    setAuth({ token: '', refreshToken: '', isAuth: false });
    navigate('/login');
  };

  const register = async (request: any, category: 'family' | 'teacher' | 'admin') => {
    setLoading(true);
    return api
      .post('/auth/signup/' + category, request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        setError(true, error.response.data.message, error.response.status);
        return error.response;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createNewToken = async (refreshToken: string | null) => {
    if (!refreshToken) return logout();
    setLoading(true);
    try {
      const response = await api.post('/auth/refresh-token', { refresh_token: refreshToken });
      if (response.status === 200) {
        const token = response.data.token;
        const roles = JSON.parse(
          CryptoJS.AES.decrypt(localStorage.getItem('roles')!, encryptionKey).toString(CryptoJS.enc.Utf8)
        );
        storeAuthData(token, refreshToken, roles);
      }
    } catch (error) {
      logout();
    } finally {
      setLoading(false);
    }
  };

  return <AuthContext.Provider value={{ auth, login, logout, register }}>{children}</AuthContext.Provider>;
};
