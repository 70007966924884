import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import sidebarLinksData from "../data/sidebarLinks.json";
import { Link } from "react-router-dom";
import Icon from "../ui/icons/Icon";

interface sidebarProps {
    pathname: string;
}

interface sidebarLinks {
    name: string;
    subPath: string,
    icon: string;
}

const ActionBar = ({ pathname }: sidebarProps) => {
    const [links, setLinks] = useState<sidebarLinks[]>([]);
    const { t } = useTranslation();
    useEffect(() => {
        switch (pathname.split("/")[1]) {
            case "teacher":
                setLinks(sidebarLinksData.teacher);
                break;
            case "family":
                setLinks(sidebarLinksData.family);
                break;
            default:
                setLinks(sidebarLinksData.family);
                break;
        }
    }, [pathname]);
    return (
        <>
            <div id="actionBar" className="fixed bottom-0 left-0 w-full bg-white p-2 flex justify-around items-center shadow-md z-10">
                {links.map((link, index) => {
                    const isActive = pathname === link.subPath;
                    return (
                        <Link
                            key={index}
                            className={`flex flex-col ${isActive ? 'align-top active' : 'align-top'}`}
                            to={link.subPath}>
                            <div className={`rounded-lg flex flex-col py-1 mx-auto`}>
                                <Icon
                                    iconName={link.icon}
                                    options={{
                                        width: 24,
                                        height: 24,
                                        activeState: isActive,
                                        className: 'mx-auto'
                                    }} />
                            </div>
                            <span className={`text-sm font-bold text-center text-red-500 ${isActive || 'hidden'}`}>{t("actionbar." + link.name)}</span>
                        </Link>
                    )
                })}
            </div>
        </>
    );
}

export default ActionBar;
