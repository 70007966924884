import React, { useEffect } from 'react';
import api from '../utils/api';
import { Subject } from '../model/registration/SchoolSelection';
import { ProfileComponent } from '../pages/family/Profile';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';

interface AddFamilyMemberProps {
  goTo: (component: ProfileComponent) => void;
}
export interface FamilyMember {
  id: number;
  first_name: string;
  last_name: string;
  school_class: string;
  school_level: string;
  school_name: string;
  subjects: Subject[];
}
const AddFamilyMember: React.FC<AddFamilyMemberProps> = ({ goTo }) => {
  const [familyMember, setFamilyMember] = React.useState<FamilyMember[]>([]);
  const { setLoading } = useLoading();
  const { setError } = useError();

  function goBack(component: ProfileComponent) {
    goTo(component);
  }

  useEffect(() => {
    setLoading(true);
    api
      .get('/family')
      .then((response) => {
        setFamilyMember(response.data.sons);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="flex flex-1 flex-col gap-4">
      {familyMember.map((member) => {
        return (
          <div key={member.id} className="flex p-4 bg-white rounded-lg shadow-md">
            <div className="w-1/2">
              <svg width="120" height="122" viewBox="0 0 120 122" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="60" cy="61" rx="60" ry="61" fill="#D10C33" />
              </svg>
            </div>
            <div className="w-1/2 flex flex-col items-center">
              <h1 className="text-xl font-bold text-fpcred">
                {member.first_name} {member.last_name}
              </h1>
              <h2 className="text-lg text-black">{member.school_name}</h2>
              <h2 className="text-lg text-black">
                {member.school_level} - {member.school_class}
              </h2>
              <h2 className="text-lg text-black">{member.subjects.map((subject) => subject.name).join(', ')}</h2>
            </div>
          </div>
        );
      })}

      <div className="flex p-4 bg-white rounded-lg shadow-md">
        <div className="w-1/2">
          <svg width="120" height="122" viewBox="0 0 120 122" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="60" cy="61" rx="60" ry="61" fill="#D10C33" />
          </svg>
        </div>
        <div className="w-1/2 flex items-center">
          <button className="p-2 bg-fpcred text-xl font-bold text-white rounded-lg">Aggiungi un familiare</button>
        </div>
      </div>
      <div>
        <button className="p-2 bg-fpcred text-xl font-bold text-white rounded-lg" onClick={() => goBack('ProfileGrid')}>
          Indietro
        </button>
      </div>
    </div>
  );
};

export default AddFamilyMember;
