import { useState, useEffect } from 'react';
import { InputField } from '../InputField';
import { ParentData, RegistrationFormData, SchoolModel } from '../../model/registration/SchoolSelection';
import { SignUpRequest } from '../../model/registration/SignUpRequest';
import useAuth from '../../hooks/useAuth';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import { City, Province } from '../../model/TeacherInfo';
import api from '../../utils/api';

interface ParentRegistrationFormProps {
  onSubmit: () => void;
  registrationData: RegistrationFormData;
  onboardingData: SchoolModel;
  updateParentData: <T extends keyof ParentData>(field: T, value: ParentData[T]) => void;
}

const ParentRegistrationForm: React.FC<ParentRegistrationFormProps> = ({
  updateParentData,
  onSubmit,
  registrationData,
  onboardingData,
}) => {
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [cities, setCities] = useState<City[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);

  const {
    parentData,
    selectedSchoolClass,
    selectedSchoolSubjects,
    selectedSchoolLevel,
    selectedSchoolType,
    selectedSchoolAddress,
  } = registrationData;

  const {
    parentFirstName,
    parentLastName,
    phone,
    city_id,
    province_id,
    street_name,
    street_number,
    zip,
    email,
    cf,
    studentFirstName,
    studentLastName,
  } = parentData;

  const childSchoolId = onboardingData[selectedSchoolLevel][selectedSchoolType].find(
    (school) => school.name === selectedSchoolAddress
  )?.id;

  if (!childSchoolId) throw new Error('School not found');

  const { register } = useAuth();
  const { setLoading } = useLoading();
  const { setError } = useError();

  const validateFirstName = (firstName: string) => {
    if (!/^[a-zA-Z]{2,}$/.test(firstName)) {
      return 'Nome deve contenere almeno 2 lettere.';
    }
    return '';
  };

  const validateLastName = (lastName: string) => {
    if (!/^[a-zA-Z]{2,}$/.test(lastName)) {
      return 'Cognome deve contenere almeno 2 lettere.';
    }
    return '';
  };

  const validatePhone = (phone: string) => {
    if (!/^\+[0-9]{10,19}$/.test(phone)) {
      return 'Telefono deve essere in formato internazionale.';
    }
    return '';
  };

  const validateEmail = (email: string) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return 'Email non valida.';
    }
    return '';
  };

  const validatePassword = (password: string) => {
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(password)) {
      return 'Password deve contenere almeno 8 caratteri, una maiuscola, una minuscola e un numero.';
    }
    return '';
  };

  const validateCF = (cf: string) => {
    if (!/^[a-zA-Z]{6}\d{2}[a-ehlmpr-tA-EHLMPR-T]{1}\d{2}[a-zA-Z]\d{3}[a-zA-Z]$/.test(cf)) {
      return 'Codice fiscale non valido.';
    }
    return '';
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    updateParentData(name as keyof ParentData, value);

    let error = '';
    switch (name) {
      case 'parentFirstName':
        error = validateFirstName(value);
        break;
      case 'parentLastName':
        error = validateLastName(value);
        break;
      case 'phone':
        error = validatePhone(value);
        break;
      case 'email':
        error = validateEmail(value);
        break;
      case 'password':
        setPassword(value);
        error = validatePassword(value);
        break;
      case 'cf':
        error = validateCF(value);
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  useEffect(() => {
    const isFormComplete =
      parentFirstName &&
      parentLastName &&
      phone &&
      email &&
      password &&
      city_id &&
      province_id &&
      street_name &&
      street_number &&
      zip &&
      studentFirstName &&
      studentLastName;

    setIsDisabled(!isFormComplete || Object.values(errors).some((error) => error));
  }, [
    parentFirstName,
    parentLastName,
    phone,
    email,
    password,
    city_id,
    province_id,
    street_name,
    street_number,
    zip,
    studentFirstName,
    studentLastName,
    errors,
  ]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      let request: SignUpRequest = {
        parent_email: email,
        parent_password: password,
        parent_first_name: parentFirstName,
        parent_last_name: parentLastName,
        parent_phone: phone,
        parent_cf: cf.toUpperCase(),
        street_name: street_name,
        street_number: street_number,
        city_id: city_id,
        province_id: province_id,
        zip,
        child_first_name: studentFirstName,
        child_last_name: studentLastName,
        child_school_id: childSchoolId,
        child_school_class: selectedSchoolClass,
        child_subjects: selectedSchoolSubjects,
        terms_and_conditions: 1,
      };
      setLoading(true);
      const response = await register(request, 'family');
      console.log(response);
      if (response.status === 200) {
        onSubmit();
      }
    } catch (error) {
      console.error(error);
      setError(true, error.response.data.message, error.response.status);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    api
      .get('/province?page_size=200')
      .then((response) => {
        setProvinces(response.data.provinces);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!province_id) return;

    setLoading(true);
    api
      .get(`/city?province_id=${province_id}&page_size=200`)
      .then((response) => {
        setCities(response.data.cities);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [province_id]);
  return (
    <div className="flex flex-col items-center justify-center w-full gap-4">
      <div>
        <h1 className="text-center font-bold text-3xl">Stai per pronotare la tua prima lezione!</h1>
        <p className="text-center text-xl">Ci serve solo qualche dato!</p>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4 max-w-6xl">
        <h2 className="text-2xl font-bold">Genitore</h2>
        <div className="flex flex-col sm:flex-row sm:justify-between gap-4">
          <InputField
            id="parentFirstName"
            name="parentFirstName"
            type="text"
            placeholder="Nome"
            value={parentFirstName}
            className={`w-full ${errors.parentFirstName ? 'border-red-500' : ''}`}
            onChange={handleChange}
            error={errors.parentFirstName}
            autocomplete="given-name"
            required
          />
          <InputField
            id="parentLastName"
            name="parentLastName"
            type="text"
            placeholder="Cognome"
            value={parentLastName}
            className={`w-full ${errors.parentLastName ? 'border-red-500' : ''}`}
            onChange={handleChange}
            error={errors.parentLastName}
            autocomplete="family-name"
            required
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:justify-between gap-4">
          <InputField
            id="phone"
            name="phone"
            type="text"
            placeholder="Telefono"
            value={phone}
            className={`w-full ${errors.phone ? 'border-red-500' : ''}`}
            onChange={handleChange}
            error={errors.phone}
            autocomplete="tel"
            required
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:justify-between gap-4">
          <select name="province_id" value={province_id} onChange={handleChange} className="w-full rounded-xl p-2">
            <option value="">Seleziona una provincia</option>
            {provinces.map((province) => (
              <option key={province.id} value={province.id}>
                {province.name}
              </option>
            ))}
          </select>

          <select
            disabled={cities.length === 0}
            name="city_id"
            value={city_id}
            onChange={handleChange}
            className="w-full rounded-xl p-2">
            <option value={0}>Seleziona una città</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-wrap gap-4">
          <InputField
            id="street_name"
            name="street_name"
            type="text"
            placeholder="Via"
            value={street_name}
            className={`flex-grow ${errors.street_name ? 'border-red-500' : ''}`}
            onChange={handleChange}
            error={errors.street_name}
            autocomplete="address-line1"
            required
          />
          <InputField
            id="street_number"
            name="street_number"
            type="text"
            placeholder="N°"
            value={street_number}
            className={`flex-grow ${errors.street_number ? 'border-red-500' : ''}`}
            onChange={handleChange}
            error={errors.street_number}
            autocomplete="address-line2"
            required
          />
          <InputField
            id="zip"
            name="zip"
            type="text"
            placeholder="CAP"
            value={zip}
            className={`flex-grow ${errors.zip ? 'border-red-500' : ''}`}
            onChange={handleChange}
            error={errors.zip}
            autocomplete="postal-code"
            required
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:justify-between gap-4">
          <InputField
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            value={email}
            className={`w-full ${errors.email ? 'border-red-500' : ''}`}
            onChange={handleChange}
            error={errors.email}
            autocomplete="email"
            required
          />
          <InputField
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            className={`w-full ${errors.password ? 'border-red-500' : ''}`}
            onChange={handleChange}
            error={errors.password}
            autocomplete="new-password"
            required
          />
        </div>
        <div>
          <InputField
            id="cf"
            name="cf"
            type="text"
            placeholder="Codice fiscale"
            value={cf}
            className={`w-full ${errors.cf ? 'border-red-500' : ''}`}
            onChange={handleChange}
            error={errors.cf}
            autocomplete="on"
            required
          />
        </div>

        <h2 className="text-2xl font-bold">Studente</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            id="studentFirstName"
            name="studentFirstName"
            type="text"
            placeholder="Nome"
            value={studentFirstName}
            className={`w-full ${errors.studentFirstName ? 'border-red-500' : ''}`}
            onChange={(e) => updateParentData('studentFirstName', e.target.value)}
            error={errors.studentFirstName}
            required
          />
          <InputField
            id="studentLastName"
            name="studentLastName"
            type="text"
            placeholder="Cognome"
            value={studentLastName}
            className={`w-full ${errors.studentLastName ? 'border-red-500' : ''}`}
            onChange={(e) => updateParentData('studentLastName', e.target.value)}
            error={errors.studentLastName}
            required
          />
        </div>
        <div className="flex items-center justify-center">
          <button
            type="submit"
            className="mt-4 bg-fpcred text-white text-xl font-bold p-2 rounded-3xl w-full md:w-auto px-8 disabled:bg-gray-400 disabled:cursor-not-allowed"
            disabled={isDisabled}>
            Prenota la tua lezione
          </button>
        </div>
      </form>
    </div>
  );
};

export default ParentRegistrationForm;

// Codice per InputField rimane invariato
