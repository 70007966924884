import React from 'react';
import { StepDetails } from '../../pages/Registration';

interface StepProgressBarProps {
    currentStepIndex: number;
    steps: StepDetails[]; // Supponendo che ogni step abbia almeno un titolo.
}

const StepProgressBar: React.FC<StepProgressBarProps> = ({ currentStepIndex, steps }) => {
    console.log(steps);
    return (
        <div className='w-full flex items-center justify-around bg-fpcpink'>
            {steps.map((_, index) => (
                <div 
                    key={index} 
                    className={`rounded-lg w-[10%] h-2 ${index <= currentStepIndex ? "bg-fpcred" : "bg-white"}`} 
                >

                </div>
            ))}
        </div>
    );
};

export default StepProgressBar;