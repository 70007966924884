import React from 'react';
import { ProfileComponent } from '../../pages/family/Profile';

interface DeleteAccountProps {
  goTo: (component: ProfileComponent) => void;
}

const DeleteAccount: React.FC<DeleteAccountProps> = ({ goTo }) => {
  function onSave(component: ProfileComponent) {
    goTo(component);
  }

  return (
    <div className="flex flex-col gap-8 p-4 items-center bg-white rounded-lg shadow-md">
      <div className="font-bold">
        <h2>Elimina account</h2>
      </div>
      <div className="flex font-bold text-center">
        <h2>
          Sei sicuro di voler <br />
          eliminare il tuo account?
        </h2>
      </div>
      <div className="flex gap-8">
        <button className="px-6 py-2 bg-fpcred rounded-lg font-bold text-white" onClick={() => onSave('ProfileGrid')}>
          Annulla
        </button>
        <button className="px-6 py-2 bg-fpcred rounded-lg font-bold text-white">Conferma</button>
      </div>
    </div>
  );
};

export default DeleteAccount;
