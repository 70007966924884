import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ErrorContextProps {
  error: ErrorData;
  setError: (error: boolean, message: string, code: number) => void;
}

interface ErrorData {
  error: boolean;
  message: string;
  code: number;
}

const ErrorContext = createContext<ErrorContextProps | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [error, setErrorState] = useState<ErrorData>({ error: false, message: '', code: 0 });

  const setError = (error: boolean, message: string, code: number) => {
    setErrorState({ error, message, code });
  };

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within a ErrorProvider');
  }
  return context;
};
