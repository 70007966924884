import React from 'react';
import { Card } from '../Card'; // Assumendo l'esistenza di questo componente

interface ClassSelectionProps {
  options: { name: string; value: string }[];
  onClassSelection: () => void;
  setSelectedSchoolClass: (selectedSchoolClass: string) => void;
}

const ClassSelection: React.FC<ClassSelectionProps> = ({ options, onClassSelection, setSelectedSchoolClass }) => {
  function onClick(selectedSchoolClass: string) {
    setSelectedSchoolClass(selectedSchoolClass);
    onClassSelection();
  }
  return (
    <div className="flex flex-col items-center justify-center gap-y-4">
      <h2 className="text-2xl font-bold mb-4">Quale anno frequenta?</h2>
      <div className="flex flex-wrap gap-4 sm:gap-6 md:gap-8 lg:gap-10 justify-center md:max-w-3xl">
        {options.map((option, index) => (
          <Card key={index} title={option.name} onClick={() => onClick(option.value)} />
        ))}
      </div>
    </div>
  );
};

export default ClassSelection;
