interface InputFieldProps {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  required?: boolean;
  error?: string;
  autocomplete?: string;
}

export const InputField: React.FC<InputFieldProps> = ({
  id,
  name,
  type,
  placeholder,
  value,
  onChange,
  className = '',
  required = false,
  error = '',
  autocomplete = 'off',
}) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <input
        required={required}
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`rounded-xl border-2 p-2`}
        autoComplete={autocomplete}
      />
      {error && <span className="text-red-500">{error}</span>}
    </div>
  );
};
