import React, { useEffect, useState } from 'react';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import api from '../../utils/api';
interface Invoice {
  client: {
    cf: string;
    city: string;
    company_name: string;
    first_name: string;
    id: number;
    last_name: string;
    province: string;
    street_name: string;
    street_number: string;
    vat_number: string;
    zip: string;
  };
  created_at: string;
  description: string;
  id: number;
  number: number;
  provider: { id: number };
  status: string;
  subtotal: number;
  uuid: string;
}

const Documents: React.FC = () => {
  const [invoices, setInvoices] = React.useState<Invoice[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const { setLoading } = useLoading();
  const { setError } = useError();

  useEffect(() => {
    setLoading(true);
    api
      .get('/invoice/passive')
      .then((response) => {
        console.log(response.data.invoices);
        setInvoices(response.data.invoices);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getPdfInvoice = (id: number) => {
    console.log(`Scaricando PDF per fattura ID: ${id}`);
    setLoading(true);
    api
      .get(`invoice/${id}/pdf`, { responseType: 'blob' })
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
      })
      .catch((error) => {
        console.error('error is:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getXmlInvoice = (id: number) => {
    console.log(`Scaricando XML per fattura ID: ${id}`);
    setLoading(true);
    api
      .get(`invoice/${id}/xml`)
      .then((response) => {
        const xmlBlob = new Blob([response.data], { type: 'application/xml' });
        const xmlUrl = URL.createObjectURL(xmlBlob);
        window.open(xmlUrl, '_blank');
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeModal = () => {
    setSelectedInvoice(null);
  };
  if (invoices.length === 0) {
    return (
      <div className="bg-white rounded-xl p-4 max-h-[500px] max-w-full overflow-auto">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Fatture</h2>
        <p className="text-lg text-gray-800">Non ci sono fatture da mostrare.</p>
      </div>
    );
  }
  return (
    <div className="w-full h-full overflow-auto flex flex-col gap-y-4 max-h-[800px]">
      <div className="bg-fpcred px-4 py-2 rounded-lg flex justify-between">
        <h1 className="text-xl font-bold text-white">I tuoi documenti</h1>
        <div>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17.0001M15 17L9 17.0001M15 17H19C19.5523 17 20 16.5523 20 16V15.4141C20 15.1489 19.8945 14.8946 19.707 14.707L19.1963 14.1963C19.0706 14.0706 19 13.9 19 13.7222V10C19 9.82357 18.9936 9.64855 18.9805 9.4761M9 17.0001L5 17.0001C4.44772 17.0001 4 16.5521 4 15.9998V15.4141C4 15.1489 4.10544 14.8949 4.29297 14.7073L4.80371 14.1958C4.92939 14.0701 5 13.9002 5 13.7224V9.99998C5 6.13401 8.134 3 12 3C12.7116 3 13.3984 3.10618 14.0454 3.30357M18.9805 9.4761C20.1868 8.7873 21 7.48861 21 6C21 3.79086 19.2091 2 17 2C15.8298 2 14.7769 2.50253 14.0454 3.30357M18.9805 9.4761C18.3966 9.80949 17.7205 10 17 10C14.7909 10 13 8.20914 13 6C13 4.9611 13.3961 4.0147 14.0454 3.30357M18.9805 9.4761C18.9805 9.47609 18.9805 9.4761 18.9805 9.4761ZM14.0454 3.30357C14.0459 3.30371 14.0464 3.30385 14.0468 3.304"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="overflow-auto p-4 bg-white rounded-lg">
        <table className="min-w-full bg-white">
          <thead className="sticky top-0 bg-white">
            <tr>
              <th className="py-2 px-4 border-b">Numero Fattura</th>
              <th className="py-2 px-4 border-b">Nome Cliente</th>
              <th className="py-2 px-4 border-b">Nome Azienda</th>
              <th className="py-2 px-4 border-b">Descrizione</th>
              <th className="py-2 px-4 border-b">Stato</th>
              <th className="py-2 px-4 border-b">Subtotale</th>
              <th className="py-2 px-4 border-b">Creata il</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr
                key={invoice.id}
                className="cursor-pointer hover:bg-gray-100"
                onClick={() => setSelectedInvoice(invoice)}>
                <td className="py-2 px-4 border-b">{invoice.number}</td>
                <td className="py-2 px-4 border-b">{`${invoice.client.first_name} ${invoice.client.last_name}`}</td>
                <td className="py-2 px-4 border-b">{invoice.client.company_name}</td>
                <td className="py-2 px-4 border-b">{invoice.description}</td>
                <td className="py-2 px-4 border-b">{invoice.status}</td>
                <td className="py-2 px-4 border-b">{invoice.subtotal.toFixed(2)}</td>
                <td className="py-2 px-4 border-b">{new Date(invoice.created_at).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedInvoice && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center" onClick={closeModal}>
          <div className="bg-white rounded-lg p-6 w-96 relative" onClick={(e) => e.stopPropagation()}>
            <button className="absolute top-2 right-2 text-gray-600 hover:text-gray-800" onClick={closeModal}>
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <h3 className="text-xl font-bold mb-4">Fattura {selectedInvoice.number}</h3>
            {selectedInvoice.client.first_name ||
              (selectedInvoice.client.last_name && (
                <p className="mb-4">
                  Cliente: {`${selectedInvoice.client.first_name} ${selectedInvoice.client.last_name}`}
                </p>
              ))}
            {selectedInvoice.client.company_name && (
              <p className="mb-4">Azienda: {selectedInvoice.client.company_name}</p>
            )}
            <p className="mb-4">Descrizione: {selectedInvoice.description}</p>
            <p className="mb-4">Subtotale: {selectedInvoice.subtotal.toFixed(2)} €</p>
            <div className="flex justify-end space-x-4">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={() => getPdfInvoice(selectedInvoice.id)}>
                Visualizza PDF
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={() => getXmlInvoice(selectedInvoice.id)}>
                Visualizza XML
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Documents;
