import React from 'react';
import { useError } from '../../context/ErrorContext';

const ErrorModal: React.FC = () => {
    const { error, setError } = useError();
    const errorMessage = error.message || 'Si è verificato un errore. Riprova più tardi.';
    const errorCode = error.code || "Generico";

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
            <div className="bg-white rounded-xl p-8 shadow-2xl max-w-2xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold mb-6 text-red-600">Errore {errorCode}</h2>
                    <p className="text-lg text-gray-700 mb-6">{errorMessage}</p>
                    <button 
                        className="mt-4 px-6 py-3 bg-red-600 text-white text-lg font-medium rounded-full hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-500 focus:ring-opacity-50"
                        onClick={() => setError(false, "", 0)}
                    >
                        Chiudi
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ErrorModal;
