import React from 'react';

const EmailConfirm: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen text-center p-4">
            <h1 className="text-2xl md:text-4xl font-bold mb-4">Registrazione avvenuta con successo!</h1>
            <p className="text-lg md:text-xl mb-8">Ti abbiamo inviato una mail per confermare il tuo indirizzo email.</p>
            <img src="https://via.placeholder.com/150" alt="Success" className="w-32 h-32 md:w-64 md:h-64 object-cover mb-8" />
            <p className="text-sm md:text-base">Se non vedi la mail, controlla nella cartella spam.</p>
        </div>
    );
};

export default EmailConfirm;