import { EventInput } from '@fullcalendar/core';
import React, { useState, useEffect } from 'react';

interface EditAvailabilityModalProps {
  selectedEvent: EventInput;
  handleTimeChange: (e: React.ChangeEvent<HTMLSelectElement>, type: string) => void;
  closeModal: () => void;
  handleSave: () => void;
  handleDelete: () => void;
  generateTimeOptions: (date: Date | string, type: string, startTime?: string) => JSX.Element[];
  handleLocationEdit: (locations: any) => void;
  handleConsecutiveLessonsEdit: (onlyConsecutiveLessons: boolean) => void;
}

const EditAvailabilityModal: React.FC<EditAvailabilityModalProps> = ({
  selectedEvent,
  handleTimeChange,
  closeModal,
  handleSave,
  handleDelete,
  generateTimeOptions,
  handleLocationEdit,
  handleConsecutiveLessonsEdit,
}) => {
  const formatTime = (date: Date | string): string => {
    const d = new Date(date);
    return d.toTimeString().slice(0, 5);
  };

  const [startTime, setStartTime] = useState<string>(formatTime(selectedEvent.start as string));
  const [endTime, setEndTime] = useState<string>(formatTime(selectedEvent.end as string));
  const [onlyConsecutiveLessons, setOnlyConsecutiveLessons] = useState<boolean>(selectedEvent.extendedProps.only_consecutive_lessons);
  const [locations, setLocations] = useState(selectedEvent.extendedProps.locations);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    console.log('selectedEventttt', selectedEvent);
    checkAtLeastOneLocation(locations);
  }, []);

  useEffect(() => {
    const endTimeDate = new Date(`1970-01-01T${endTime}:00`);
    const startTimeDate = new Date(`1970-01-01T${startTime}:00`);

    // Aggiorna l'orario di fine solo se è precedente o uguale a un'ora dopo l'inizio
    if (endTimeDate.getTime() - startTimeDate.getTime() < 60 * 60 * 1000) {
      const newEndTime = formatTime(new Date(startTimeDate.getTime() + 60 * 60 * 1000).toISOString());
      setEndTime(newEndTime);
      handleTimeChange({ target: { value: newEndTime } } as React.ChangeEvent<HTMLSelectElement>, 'end');
    }
  }, [startTime]);

  if (!selectedEvent) return null;

  const handleStartTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newStartTime = e.target.value;
    setStartTime(newStartTime);
    handleTimeChange(e, 'start');
  };

  const handleEndTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newEndTime = e.target.value;
    setEndTime(newEndTime);
    handleTimeChange(e, 'end');
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>, location: string) => {
    const newLocations = { ...locations, [location]: {...locations[location], enabled:e.target.checked} };
    setLocations(newLocations);
    checkAtLeastOneLocation(newLocations);
  };

  const checkAtLeastOneLocation = (locations: any) => {
    console.log('locations', locations);
    const atLeastOneSelected = Object.values(locations).some((value:any) => value.enabled);
    setIsSaveDisabled(!atLeastOneSelected);
    handleLocationEdit(locations);
  };

  const handleConsecutiveLessonsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyConsecutiveLessons(e.target.checked);
    handleConsecutiveLessonsEdit(e.target.checked);
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Modifica disponibilità</h3>
          <div className="mt-2 px-7 py-3">
            <form>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="start">
                  Orario Inizio
                </label>
                <select
                  id="start"
                  name="start"
                  value={startTime}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={handleStartTimeChange}>
                  {generateTimeOptions(selectedEvent.start as string, 'start')}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="end">
                  Orario Fine
                </label>
                <select
                  id="end"
                  name="end"
                  value={endTime}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={handleEndTimeChange}>
                  {generateTimeOptions(selectedEvent.end as string, 'end', startTime)}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="recurrency-toggle">
                  Lezioni Consecutive
                </label>
                <input
                  type="checkbox"
                  id="recurrency-toggle"
                  name="recurrency-toggle"
                  checked={onlyConsecutiveLessons}
                  onChange={handleConsecutiveLessonsChange}
                  className="mr-2 leading-tight"
                />
                <span className="text-sm text-gray-600">Abilita/Disabilita Lezioni consecutive</span>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Località</label>
                <div className="flex flex-wrap justify-between">
                  <div className="w-1/2 mb-4">
                    <input
                      type="checkbox"
                      id="location-headquarter"
                      name="location-headquarter"
                      checked={locations.headquarter.enabled}
                      onChange={(e) => handleLocationChange(e, 'headquarter')}
                      className="mr-2 leading-tight"
                    />
                    <label htmlFor="location-headquarter" className="text-sm text-gray-600">
                      In sede
                    </label>
                  </div>
                  <div className="w-1/2 mb-4">
                    <input
                      type="checkbox"
                      id="location-home"
                      name="location-home"
                      checked={locations.home.enabled}
                      onChange={(e) => handleLocationChange(e, 'home')}
                      className="mr-2 leading-tight"
                    />
                    <label htmlFor="location-home" className="text-sm text-gray-600">
                      A domicilio
                    </label>
                  </div>
                  <div className="w-1/2 mb-4">
                    <input
                      type="checkbox"
                      id="location-home-from-headquarter"
                      name="location-home-from-headquarter"
                      checked={locations.home_from_headquarter.enabled}
                      onChange={(e) => handleLocationChange(e, 'home_from_headquarter')}
                      className="mr-2 leading-tight"
                    />
                    <label htmlFor="location-home-from-headquarter" className="text-sm text-gray-600">
                      A domicilio vicino alla sede
                    </label>
                  </div>
                  <div className="w-1/2 mb-4">
                    <input
                      type="checkbox"
                      id="location-online"
                      name="location-online"
                      checked={locations.online.enabled}
                      onChange={(e) => handleLocationChange(e, 'online')}
                      className="mr-2 leading-tight"
                    />
                    <label htmlFor="location-online" className="text-sm text-gray-600">
                      Online
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="items-center px-4 py-3">
            <button
              className={`px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300 ${
              isSaveDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isSaveDisabled}
              onClick={handleSave}>
              Modifica
            </button>
            <button
              className="mt-2 px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
              onClick={closeModal}>
              Indietro
            </button>
            <button
              className="mt-2 px-4 py-2 bg-red-700 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-red-300"
              onClick={handleDelete}>
              Elimina disponibilità
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAvailabilityModal;
