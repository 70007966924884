import React, { useEffect, useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, EventClickArg, EventInput } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import EditAvailabilityModal from '../modal/EditAvailabilityModal';
import AddAvailabilityModal from '../modal/AddAvailabilityModal';
import { AxiosResponse } from 'axios';
import useScreenWidth from '../../hooks/useScreenWidth';

interface CalendarProps {
  lessonEvent: EventInput[];
  availabilitiesEvent: EventInput[];
  editAvailability: (availability: EventInput) => Promise<AxiosResponse<any, any>>;
  deleteAvailability: (availabilityId: number) => Promise<void>;
  createAvailability: (availability: EventInput) => Promise<AxiosResponse<any, any>>;
}

const AvailabilitiesCalendar: React.FC<CalendarProps> = ({
  lessonEvent,
  availabilitiesEvent,
  editAvailability,
  deleteAvailability,
  createAvailability,
}) => {
  const [events, setEvents] = useState<EventInput[]>(availabilitiesEvent);
  const [render, setRender] = useState(false);
  const [initialView, setInitialView] = useState<string>('timeGridWeek');
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [addModalOpened, setAddModalOpened] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventInput | null>(null);
  const calendarRef = useRef<FullCalendar>(null);
  const screenWidth = useScreenWidth();

  const today = new Date(Date.now());
  const start = new Date(new Date().setDate(today.getDate() - 30));
  const maxDate = new Date(new Date().setDate(today.getDate() + 30));

  useEffect(() => {
    console.log('AvailabilitiesCalendar useEffect triggered');
    setRender(true);
  }, []);

  useEffect(() => {
    setEvents([...availabilitiesEvent.map(event=>({...event,editable:false})), ...lessonEvent.map(event=>({...event,groupId:'lesson'}))]);
  }, [availabilitiesEvent, lessonEvent]);

  useEffect(() => {
    if (screenWidth < 1024) {
      setInitialView('timeGridDay');
    } else {
      setInitialView('timeGridWeek');
    }
  }, [screenWidth]);

  const handleDateClick = (arg: any) => {
    if (arg.view.type === 'dayGridMonth' || arg.view.type === 'dayGridYear') {
      let calendarApi = arg.view.calendar;
      calendarApi.changeView('timeGridDay', arg.date);
    }
  };

  const renderEventContent = (eventInfo) => {
    if (eventInfo.event.title === 'Lezione') {
      return (
        <>
          <p className="text-fpcred">Prenotata</p>
        </>
      );
    } else if (eventInfo.event.extendedProps.customHtml) {
      return eventInfo.event.extendedProps.customHtml;
    }
    else { return null; }
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    console.log('Event clicked:', clickInfo); // Check if this gets logged
    const eventIndex = events.findIndex((event) => event.id === clickInfo.event.id);
    console.log('Event selected is:', events[eventIndex]);
    if (clickInfo.event.groupId === 'lesson') {
      return;
    } else {
    setSelectedEvent(events[eventIndex]);
    setEditModalOpened(true);
    }
  };

  const closeModal = () => {
    setAddModalOpened(false);
    setEditModalOpened(false);
    setSelectedEvent(null);
  };

  const handleSave = () => {
    if (selectedEvent) {
      const updatedEvent = {
        ...selectedEvent,
        start: new Date(selectedEvent.start as string),
        end: new Date(selectedEvent.end as string),
      };

      const formattedStart = formatDateForAPI(updatedEvent.start);
      const formattedEnd = formatDateForAPI(updatedEvent.end);

      editAvailability({
        ...updatedEvent,
        start: formattedStart,
        end: formattedEnd,
      }).then((response) => {
        const updatedEventFromResponse = response.data.availabilities[0];
        const updatedEventWithId = {
          ...updatedEvent,
          id: updatedEventFromResponse.id.toString(),
          title: `Availability ${updatedEventFromResponse.id}`,
          start: new Date(updatedEventFromResponse.start_date_time),
          end: new Date(updatedEventFromResponse.end_date_time),
          extendedProps: {
            ...updatedEvent.extendedProps,
            locations: updatedEventFromResponse.locations,
            teacher_id: updatedEventFromResponse.teacher_id,
            group_available_id: updatedEventFromResponse.group_available_id,
            only_consecutive_lessons: updatedEventFromResponse.only_consecutive_lessons,
          },
        };
        setEvents((prevEvents) => {
          const updatedEvents = prevEvents.map((event) => {
            if (event.id === selectedEvent.id) {
              return updatedEventWithId;
            }
            return event;
          });

          const calendarApi = calendarRef.current?.getApi();
          const fullCalendarEvent = calendarApi?.getEventById(selectedEvent.id);
          if (fullCalendarEvent) {
            fullCalendarEvent.remove();
            calendarApi.addEvent(updatedEventWithId);
          }

          return updatedEvents;
        });
        closeModal();
      });
    }
  };

  const handleCreate = (availability: any) => {
    const formattedStart = formatDateForAPI(new Date(availability.start_date_time));
    const formattedEnd = formatDateForAPI(new Date(availability.end_date_time));

    console.log('availability:      ', availability);
    createAvailability({
      ...availability,
      start: formattedStart,
      end: formattedEnd,
    }).then((response) => {
      const res = response.data.availabilities;
      let newEvent: EventInput[] = [];
      res.forEach((availability) => {
        newEvent.push({
          id: availability.id.toString(),
          title: `Availability ${availability.id}`,
          start: new Date(availability.start_date_time),
          end: new Date(availability.end_date_time),
          display: 'block',
          classNames: ['cursor-pointer', 'bg-green-200', 'border-green-500'],
          editable: true,
          extendedProps: {
            locations: availability.locations,
            teacher_id: availability.teacher_id,
            group_available_id: availability.group_available_id,
            only_consecutive_lessons: availability.only_consecutive_lessons,
          },
        });
      });
      setEvents((prevEvents) => [...prevEvents, ...newEvent]);

      const calendarApi = calendarRef.current?.getApi();
      calendarApi?.addEventSource(newEvent);

      closeModal();
    });
  };

  const generateTimeOptions = (date: Date | string, type: string, startTime?: string) => {
    const times = [];
    const d = new Date(date);
    d.setMinutes(0);
    d.setSeconds(0);
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 30) {
        const hour = `0${i}`.slice(-2);
        const minute = `0${j}`.slice(-2);
        times.push(`${hour}:${minute}`);
      }
    }

    if (type === 'end' && startTime) {
      const start = new Date(`1970-01-01T${startTime}:00`);
      return times
        .filter((time) => {
          const end = new Date(`1970-01-01T${time}:00`);
          return end > start && end.getTime() - start.getTime() >= 60 * 60 * 1000;
        })
        .map((time) => (
          <option key={time} value={time}>
            {time}
          </option>
        ));
    }

    return times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ));
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLSelectElement>, type: 'start' | 'end') => {
    if (selectedEvent) {
      const date = new Date(selectedEvent[type] as string);
      const [hours, minutes] = e.target.value.split(':');
      date.setHours(parseInt(hours), parseInt(minutes));
      const updatedEvent = { ...selectedEvent, [type]: date.toISOString() };
      setSelectedEvent(updatedEvent);
      if (type === 'start') {
        const endDate = new Date(selectedEvent.end as string);
        if (endDate.getTime() - date.getTime() < 60 * 60 * 1000) {
          const newEndDate = new Date(date.getTime() + 60 * 60 * 1000);
          setSelectedEvent({ ...updatedEvent, end: newEndDate.toISOString() });
        }
      }
    }
  };

  const handleConsecutiveLessonsEdit = (onlyConsecutiveLessons: boolean) => {
    if (selectedEvent) {
      console.log('onlyConsecutiveLessons:', onlyConsecutiveLessons);
      setSelectedEvent({
        ...selectedEvent,
        extendedProps: { ...selectedEvent.extendedProps, only_consecutive_lessons: onlyConsecutiveLessons },
      });
    }
  };

  const handleLocationEdit = (locations: any) => {
    if (selectedEvent) {
      console.log('locations:', locations);
      setSelectedEvent({
        ...selectedEvent,
        extendedProps: { ...selectedEvent.extendedProps, locations },
      });
    }
  };

  function handleDelete() {
    if (selectedEvent) {
      deleteAvailability(Number(selectedEvent.id)).then(() => {
        const updatedEvents = events.filter((event) => event.id !== selectedEvent.id);
        setEvents(updatedEvents);

        const calendarApi = calendarRef.current?.getApi();
        const fullCalendarEvent = calendarApi?.getEventById(selectedEvent.id as string);
        fullCalendarEvent?.remove();

        closeModal();
      });
    }
  }

  function selectDate(selectInfo: DateSelectArg) {
    setSelectedEvent({ start: selectInfo.startStr, end: selectInfo.endStr, id: 'new' });
    setAddModalOpened(true);
  }

  const formatDateForAPI = (date: Date): string => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const hh = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    const ss = String(date.getSeconds()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
  };

  return (
    <>
      {render && (
        <FullCalendar
          ref={calendarRef}
          key={initialView}
          eventClick={handleEventClick}
          locale={itLocale}
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView={initialView}
          headerToolbar={{
            start: 'prev',
            center: 'title',
            end: 'next',
          }}
          editable={false}
          eventDragMinDistance={99}
          droppable={false}
          selectable={true}
          selectMirror={true}
          weekends={true}
          validRange={{ start: start, end: maxDate }}
          events={events}
          dateClick={handleDateClick}
          allDaySlot={false}
          height={'100%'}
          eventContent={renderEventContent}
          select={(selectInfo: DateSelectArg) => selectDate(selectInfo)}
          slotMinTime={'07:00:00'}
          slotMaxTime={'22:00:00'}
          selectLongPressDelay={70}
        />
      )}
      {editModalOpened && selectedEvent && (
        <EditAvailabilityModal
          selectedEvent={selectedEvent}
          closeModal={closeModal}
          handleSave={handleSave}
          handleDelete={handleDelete}
          handleTimeChange={handleTimeChange}
          generateTimeOptions={generateTimeOptions}
          handleConsecutiveLessonsEdit={handleConsecutiveLessonsEdit}
          handleLocationEdit={handleLocationEdit}
        />
      )}
      {addModalOpened && (
        <AddAvailabilityModal
          selectedEvent={selectedEvent}
          closeModal={closeModal}
          handleCreate={handleCreate}
          handleTimeChange={handleTimeChange}
          generateTimeOptions={generateTimeOptions}
        />
      )}
    </>
  );
};

export default AvailabilitiesCalendar;
