import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
function AdminLogin() {
  const router = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login(email, password, 'admin');
  };

  function goToForgotPassword() {
    router('/forgot-password');
  }

  return (
    <div className="flex items-center justify-center h-screen bg-fpcpink">
      <div className="py-5 px-8 bg-white rounded-b-3xl shadow-xl">
        <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
          <h2 className="text-center text-2xl font-bold">{t('login.title').toUpperCase()}</h2>
          <p className="text-center text-xl">{t('login.description')}</p>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('login.emailPlaceholder')}
            className="border py-2.5 px-5 rounded-3xl text-xl font-normal placeholder:text-[#8A8A8A] placeholder:font-normal"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('login.passwordPlaceholder')}
            className="border py-2.5 px-5 rounded-3xl text-xl font-normal placeholder:text-[#8A8A8A] placeholder:font-normal"
          />
          <button type="submit" className="bg-red-500 text-white px-4 py-2 rounded">
            {t('login.button')}
          </button>
          {/* <div className="text-center">
            <p className="text-xl font-normal text-fpcred cursor-pointer" onClick={goToForgotPassword}>
              Hai dimenticato la password?
            </p>
          </div> */}
        </form>
      </div>
    </div>
  );
}

export default AdminLogin;
