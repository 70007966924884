import React from 'react';
import { Outlet } from 'react-router-dom';

export interface StepDetails {
  stepComponent: React.ReactNode;
  stepId: string;
}

const Registration: React.FC = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default Registration;
