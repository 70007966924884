interface CardProps {
    title: string;
    onClick?: () => void;
    selected?: boolean;
}

export const Card: React.FC<CardProps> = ({ title, selected, onClick }) => (
    <div onClick={onClick} className={`w-[150px] sm:w-56 flex items-center justify-center select-none rounded-3xl border-b-4 border-fpcred ${!selected ? "bg-white" : "bg-fpcred"} shadow-lg cursor-pointer transform transition duration-300 hover:scale-105 p-4 sm:p-6 md:p-8`}>
        <div className="text-center">
            <p className="font-bold text-lg sm:text-xl md:text-2xl">{title}</p>
        </div>
    </div>
);