import React, { useState, useEffect, useRef } from 'react';
import { Subject } from '../model/TeacherInfo';
import { SubjectsAndSchools } from '../model/registration/TeacherForm';

interface SubjectAndSchoolSelectionProps {
  data: EducationData;
  subjects_and_schools: SubjectsAndSchools[];
  onUpdate: (subjectsAndSchools: SubjectsAndSchools[]) => void;
}

interface School {
  id: number;
  name: string;
  subjects: Subject[];
}

interface SchoolCategory {
  [key: string]: School[];
}

export interface EducationData {
  [key: string]: SchoolCategory;
}

interface Selection {
  id: number;
  category: string;
  schoolType: string;
  schoolId: number;
  subjects: number[];
  years: number[];
}

// Funzione di confronto profondo
const deepEqual = (obj1: any, obj2: any) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const SubjectAndSchoolSelection: React.FC<SubjectAndSchoolSelectionProps> = ({ data, onUpdate }) => {
  const [selections, setSelections] = useState<Selection[]>([]);
  const [currentSelection, setCurrentSelection] = useState<Omit<Selection, 'id'>>({
    category: '',
    schoolType: '',
    schoolId: -1,
    subjects: [],
    years: [],
  });

  const previousSelectionsRef = useRef<SubjectsAndSchools[]>([]);

  const categoryNames = {
    hs: 'Scuola Superiore',
    ms: 'Scuola media',
  };

  useEffect(() => {
    const mappedSelections: SubjectsAndSchools[] = selections.flatMap((selection) =>
      selection.subjects.map((subject) => ({
        subject,
        school: selection.schoolId,
        classes: selection.years.map((year) => year.toString()),
      }))
    );

    // Solo chiamare onUpdate se le selezioni sono effettivamente cambiate
    if (!deepEqual(mappedSelections, previousSelectionsRef.current)) {
      previousSelectionsRef.current = mappedSelections;
      onUpdate(mappedSelections);
    }
  }, [selections, onUpdate]);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentSelection({
      category: e.target.value,
      schoolType: '',
      schoolId: -1,
      subjects: [],
      years: [],
    });
  };

  const handleSchoolTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentSelection((prev) => ({
      ...prev,
      schoolType: e.target.value,
      schoolId: -1,
      subjects: [],
      years: [],
    }));
  };

  const handleSchoolChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentSelection((prev) => ({
      ...prev,
      schoolId: Number(e.target.value),
      subjects: [],
      years: [],
    }));
  };

  const handleSubjectsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setCurrentSelection((prev) => ({
      ...prev,
      subjects: e.target.checked ? [...prev.subjects, value] : prev.subjects.filter((id) => id !== value),
    }));
  };

  const handleYearsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setCurrentSelection((prev) => ({
      ...prev,
      years: e.target.checked ? [...prev.years, value] : prev.years.filter((id) => id !== value),
    }));
  };

  const handleAddSelection = () => {
    const newSelection: Selection = { id: Date.now(), ...currentSelection };
    setSelections((prevSelections) => [...prevSelections, newSelection]);
    setCurrentSelection({
      category: '',
      schoolType: '',
      schoolId: -1,
      subjects: [],
      years: [],
    });
  };

  const handleRemoveSelection = (id: number) => {
    setSelections((prevSelections) => prevSelections.filter((selection) => selection.id !== id));
  };

  const renderSchoolTypes = () => {
    if (!currentSelection.category) return null;

    const schoolTypes = Object.keys(data[currentSelection.category]).filter(
      (type) => data[currentSelection.category][type].length > 0
    );

    return (
      <select
        value={currentSelection.schoolType}
        onChange={handleSchoolTypeChange}
        className="w-full border border-gray-400 rounded-lg px-2 text-lg py-2">
        <option value="" disabled>
          Seleziona il tipo di scuola
        </option>
        {schoolTypes.map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>
    );
  };

  const renderSchools = () => {
    if (!currentSelection.category || !currentSelection.schoolType) return null;

    const schools = data[currentSelection.category][currentSelection.schoolType];
    const selectedSchools = selections.map((sel) => sel.schoolId);

    return (
      <select
        value={currentSelection.schoolId !== -1 ? currentSelection.schoolId.toString() : ''}
        onChange={handleSchoolChange}
        className="w-full border border-gray-400 rounded-lg px-2 text-lg py-2">
        <option value="" disabled>
          Seleziona la scuola
        </option>
        {schools
          .filter((school) => !selectedSchools.includes(school.id))
          .map((school) => (
            <option key={school.id} value={school.id}>
              {school.name}
            </option>
          ))}
      </select>
    );
  };

  const renderSubjects = () => {
    if (!currentSelection.category || !currentSelection.schoolType || currentSelection.schoolId === -1) return null;

    const schools = data[currentSelection.category][currentSelection.schoolType];
    const selectedSchoolObj = schools.find((school) => school.id === currentSelection.schoolId);

    if (!selectedSchoolObj) return null;

    return (
      <div className="flex flex-col">
        <label>Seleziona le materie</label>
        {selectedSchoolObj.subjects.map((subject) => (
          <label key={subject.id} className="flex items-center">
            <input
              type="checkbox"
              value={subject.id}
              checked={currentSelection.subjects.includes(subject.id)}
              onChange={handleSubjectsChange}
            />
            <span className="ml-2">{subject.name}</span>
          </label>
        ))}
      </div>
    );
  };

  const renderYears = () => {
    if (!currentSelection.category || !currentSelection.schoolType || currentSelection.schoolId === -1) return null;

    const years = currentSelection.category === 'ms' ? [1, 2, 3] : [1, 2, 3, 4, 5];

    return (
      <div className="flex flex-col">
        <label>Seleziona gli anni</label>
        {years.map((year) => (
          <label key={year} className="flex items-center">
            <input
              type="checkbox"
              value={year}
              checked={currentSelection.years.includes(year)}
              onChange={handleYearsChange}
            />
            <span className="ml-2">{year}</span>
          </label>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-2">
      <select
        value={currentSelection.category}
        onChange={handleCategoryChange}
        className="w-full border border-gray-400 rounded-lg px-2 text-lg py-2">
        <option value="" disabled>
          Seleziona la categoria
        </option>
        {Object.keys(data).map((category) => (
          <option key={category} value={category}>
            {categoryNames[category]|| category}
          </option>
        ))}
      </select>

      {renderSchoolTypes()}
      {renderSchools()}
      {renderSubjects()}
      {renderYears()}

      {currentSelection.category &&
        currentSelection.schoolType &&
        currentSelection.schoolId !== -1 &&
        currentSelection.subjects.length > 0 &&
        currentSelection.years.length > 0 && (
          <button
            onClick={handleAddSelection}
            className="w-full bg-blue-500 text-white rounded-lg px-2 text-lg py-2 mt-2">
            Aggiungi selezione
          </button>
        )}

      <div className="mt-4">
        <h3 className="text-lg font-bold">Selezioni attuali:</h3>
        {selections.map((selection) => (
          <div
            key={selection.id}
            className="p-2 border border-gray-300 rounded-lg mt-2 flex justify-between items-center">
            <div>
              <p>Categoria: {selection.category}</p>
              <p>Tipo di scuola: {selection.schoolType}</p>
              <p>
                Scuola:{' '}
                {
                  data[selection.category][selection.schoolType].find((school) => school.id === selection.schoolId)
                    ?.name
                }
              </p>
              <p>
                Materie:{' '}
                {selection.subjects
                  .map(
                    (subjectId) =>
                      data[selection.category][selection.schoolType]
                        .find((school) => school.id === selection.schoolId)
                        ?.subjects.find((subject) => subject.id === subjectId)?.name
                  )
                  .join(', ')}
              </p>
              <p>Anni: {selection.years.join(', ')}</p>
            </div>
            <button
              onClick={() => handleRemoveSelection(selection.id)}
              className="ml-4 bg-red-500 text-white rounded-lg px-2 text-lg py-2">
              Rimuovi
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubjectAndSchoolSelection;
